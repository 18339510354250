import { useNavigate } from 'react-router-dom'
import { ScanResponse } from 'client/scanner/scanner.types';

interface Go {
  origin: ScanResponse,
  ean: string
}

export const CREATE_PRODUCT_URL = '/inventory-fix/:location/:container/product/:ean/new'

export const useGoToCreateProduct = () => {
  const navigate = useNavigate()

  return {
    go: ({ origin, ean }: Go) => {
      const url = CREATE_PRODUCT_URL
      .replace(':location', origin.location?.name as string)
      .replace(':container', origin.container?.name || 'null')
      .replace(':ean', ean)
      navigate(url)
    }
  }
}
