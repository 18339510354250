import { useNavigate } from 'react-router-dom'

export const useGoToContainerDetail = () => {
  const navigate = useNavigate()

  return {
    go: (containerName: string) => {
      navigate(
        STORAGE_CONTAINER_DETAIL_URL.replace(':containerName', containerName)
      )
    }
  }
}

export const STORAGE_CONTAINER_DETAIL_URL = '/to-storage/:containerName/detail'
