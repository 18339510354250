import {useEffect, useState} from "react";
import { type ProductWithInfo } from "../universal-search.types";
import {onErrorToast} from "../../../onErrorToast.util";
import { useAtom } from "jotai";
import { universalSearchScanAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";
import { findProductsInfo } from "client/products/products.client";
import { UomKey } from "client/types";
import { getProducts } from "client/inventory/inventory.client";
import { LocationResponse } from "client/scanner/scanner.types";

export const useUniversalSearchLocation = () => {
  const [scan] = useAtom(universalSearchScanAtomReader)
  const [products, setProducts] = useState<ProductWithInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const location = scan.location as LocationResponse
  const totalContainers = scan.totalContainers

  useEffect(() => {
    if(!location) return
    setLoading(true)
    getProducts(location.name)
    .then(async (products) => {
      const tempProducts = products.length > 0 ? await findProductsInfo(products.map((it) => it.productEan)) : []
      return products.map((it) => {
        const product = tempProducts.find((p) => p.ean === it.productEan)
        let productWithExtraInfo: ProductWithInfo = {...it, productName: '', productImage: '', isWeighable: false }
        if (product != null) {
          productWithExtraInfo.productName = product.name as string
          productWithExtraInfo.productImage = product.image as string
          productWithExtraInfo.isWeighable = product.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
        }
        return productWithExtraInfo
      })
    })
    .then(e => setProducts(e))
    .catch(e => {
      onErrorToast("Error al obtener productos de inventario en ubicación: " + location.name + ": " + e.message)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [])

  return {
    location,
    totalContainers,
    products,
    loading,
    goBack: () => navigate(-1)
  };
}
