import { useNavigate } from 'react-router-dom'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'

export const useScanDestinationNavigator = () => {
  const navigate = useNavigate()

  return {
    go: (origin: ScannedLocation | ScannedContainer) => {
      const text = origin.type === ScanType.Location ? origin.location.name : origin.container.name
      navigate(SCAN_DESTINATION_URL.replace(':origin', text))
    }
  }
}

export const SCAN_DESTINATION_URL = '/transfer/:origin/scan-destination'
