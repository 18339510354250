import { getLocationFromScan } from 'client/scanner/scanner.client'
import { type ScannedLocation } from 'client/scanner/scanner.types'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Analytics } from '../../../analytics/analytics.events'
import { useScanner } from '../../../hooks/scanner.hook'
import { type Location } from '../../../types/model'
import {
  getSuggestedLocationsInStorage,
  storeContainer,
  storeProducts
} from '../Storage.client'
import { useGoToStorage } from '../Storage.navigator'
import { selectedContainerReader, selectedProductsReader } from '../Storage.state'
import { type SuggestedLocation } from '../Storage.types'
import { validateDestination } from './StorageScanDestination.validator'

export interface StorageScanDestination {
  suggestedLocations: Location[]
  locationAndContainer: Required<ScannedLocation>
}

export const useStorageScanDestination = () => {
  const { t } = useTranslation('global')

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [suggestions, setSuggestions] = useState<SuggestedLocation[]>([])

  const { keys, getScan } = useScanner()

  const storagePage = useGoToStorage()

  const [selectedProducts] = useAtom(selectedProductsReader)
  const [selectedContainer] = useAtom(selectedContainerReader)

  const [params] = useSearchParams()
  const moveAll = params.get('moveAll') === 'true'

  const makeStorageMovement = async (target) => {
    if (moveAll) {
      return await storeContainer({
        source: selectedContainer.container.name,
        target
      }).catch((e: Error) => {
        throw e
      })
    } else {
      return await storeProducts({
        source: selectedContainer.container.name,
        target,
        products: selectedProducts.map((p) => ({
          ean: p.ean,
          quantity: p.stock
        }))
      }).catch((e: Error) => {
        throw e
      })
    }
  }

  // On init
  useEffect(() => {
    getSuggestedLocationsInStorage(selectedContainer.container.name)
      .then((res) => {
        setSuggestions(res)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
  }, [])

  // On scan
  useEffect(() => {
    const scanned = getScan()
    if (scanned && !showLoader) {
      setShowLoader(true)
      getLocationFromScan(scanned)
        .then((res) => validateDestination(res, moveAll))
        .then(async (res) => await makeStorageMovement(res.location.name))
        .then((e) => {
          toast.success(
            t('storage-scan-destination.ok', {
              container: selectedContainer.container.name,
              origin: selectedContainer.location.name,
              destination: scanned
            })
          )

          Analytics.storage({
            origin: {
              locationName: selectedContainer.location.name,
              locationType: 'PreStorage',
              containerId: selectedContainer.container.legacyId,
              containerName: selectedContainer.container.name
            },
            destination: {
              locationName: e.target,
              locationType: 'Storage',
              containerId: e.target,
              containerName: e.target
            }
          })

          setTimeout(() => {
            setShowLoader(false)
            storagePage.go()
          }, 2000)
        })
        .catch((e: Error) => {
          toast.error(e.message)
        })
        .finally(() => {
          setShowLoader(false)
        })
    }
  }, [keys, getScan])

  return {
    suggestedLocations: suggestions,
    origin: selectedContainer,
    isLoading: showLoader
  }
}
