import { useNavigate } from 'react-router-dom'

export const TRANSFER_SCAN_ORIGIN = '/traslation/scan-origin'

export const useTransferScanOriginNavigator = () => {
  const navigate = useNavigate()
  return {
    go: () => { navigate(TRANSFER_SCAN_ORIGIN) }
  }
}
