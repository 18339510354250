import { type InventoryProduct, type LocationAndContainer, type NewInventoryProduct } from '../types/model'
import { restockMetricsAxiosInstance } from 'utils/restock-metrics.axios.instance'
import { inventoryWmsUtilsAxiosInstance } from 'utils/op-inventory-wms-utils.axios.instance'

export interface MoveContainerProps {
  source: string
  target: string
}

export interface MoveProductsProps {
  source: string
  target: string
  products: NewInventoryProduct[]
}

interface MoveProductsMetricsProps {
  product: InventoryProduct
  origin: LocationAndContainer
  destination: LocationAndContainer
}

export const moveProducts = async ({
  source,
  target,
  products
}: MoveProductsProps) => {
  return await inventoryWmsUtilsAxiosInstance()
    .post('v2/move/products', {
      source,
      target,
      products: products.map(e => ({
        ean: e.productEan,
        quantity: e.quantity ?? e.stock
      }))
    })
}

export const moveContainer = async ({
  source,
  target
}: MoveContainerProps) => {
  return await inventoryWmsUtilsAxiosInstance().post(`/v2/move/containers/${source}`, { target })
}

export const moveProductsMetrics = async ({
  product,
  origin,
  destination
}: MoveProductsMetricsProps) => {
  return await restockMetricsAxiosInstance()
    .post('/data-metrics-notifications/restock', {
      ean: product.productEan,
      sku: product.productSku,
      quantity: product.stock,
      sourceLocation: origin.locationName,
      targetLocation: destination.locationName,
      container: destination.containerName
    })
}
