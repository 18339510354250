import { useTranslation } from 'react-i18next'
import ImageProduct from '../../assets/images/Utilities/Illustration product.svg'
import ImageContainer from '../../assets/images/Utilities/Illustration_container.svg'
import ImageLooseProduct from '../../assets/images/Utilities/Illustration_single_product.svg'
import { AtlasBodyMain, AtlasBodyTitle, AtlasListAtom, CardComponent } from '@justomx/atlas-components'

export type TransferOptionSelected = 'move-products' | 'move-container' | 'move-loose-products'

interface MoveTypeDetail {
  img: string
  alt: string
  title: string
  items: string[]
}

interface MoveProductsSelectorMoleculeProps {
  type: TransferOptionSelected
  onClick: () => void
}

export const MoveProductsSelectorMolecule = ({
  type,
  onClick
}: MoveProductsSelectorMoleculeProps) => {
  const { t } = useTranslation('global')

  const MOVE_PARAMS: Record<TransferOptionSelected, MoveTypeDetail> = {
    'move-container': {
      img: ImageContainer,
      alt: 'Image showing how to move a container from a location',
      title: t('move-products-selector.container.title'),
      items: t('move-products-selector.container.items', { returnObjects: true })
    },
    'move-products': {
      img: ImageProduct,
      alt: 'Image showing how to move a product to a location',
      title: t('move-products-selector.product.title'),
      items: t('move-products-selector.product.items', { returnObjects: true })
    },
    'move-loose-products': {
      img: ImageLooseProduct,
      alt: 'Image showing how to move a loose product to a location',
      title: t('move-products-selector.loose-product.title'),
      items: t('move-products-selector.loose-product.items', { returnObjects: true })
    }
  }

  const params = MOVE_PARAMS[type]

  return <CardComponent onClick={onClick}>
    <img src={params.img} alt={params.alt} />
    <AtlasBodyTitle>{params.title}</AtlasBodyTitle>
    <AtlasListAtom>
      {params.items.map(e => <li key={e}>
        <AtlasBodyMain display="contents">{e}</AtlasBodyMain>
      </li>)}
    </AtlasListAtom>
  </CardComponent>
}
