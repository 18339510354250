import { useStorageScanDestination } from './StorageScanDestination.hook'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import { MoveSuggestionsOrganism } from '../../../components/organisms/MoveSuggestions.organism'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'
import LoaderAtom from 'components/atoms/Loader.atom'

export const StorageScanDestinationPage = () => {
  const { suggestedLocations, origin, isLoading } = useStorageScanDestination()
  const { t } = useTranslation('global')

  if (isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <MenuWrapperOrganism title={t('storage-scan-destination.scan')} />
      <PageContentAtom $centered>
        <Filler flexGrow={1} />
        <Icon src={Icons.ScanIcon} />
        <AtlasBodyTitle>{t('storage-scan-destination.scan')}</AtlasBodyTitle>
        <AtlasH1 style={{ textAlign: 'center' }}>
          {t('storage-scan-destination.location')}
        </AtlasH1>
        <AtlasBodyTitle>
          {t('storage-scan-destination.use-your-scanner')}
        </AtlasBodyTitle>
        <Filler />
        <MoveSuggestionsOrganism
          origin={origin}
          destinations={suggestedLocations}
        />
      </PageContentAtom>
    </>
  )
}
