import {useUniversalSearch} from "./universal-search.hooks";
import {
  AtlasBodyMain,
  AtlasBodyTitle,
  AtlasColor,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  TextBoxMolecule
} from "@justomx/atlas-components";
import {useTranslation} from "react-i18next";
import LoaderAtom from "../../components/atoms/Loader.atom";
import { MenuWrapperOrganism } from "components/organisms/MenuWrapper.organism";

export const UniversalSearchPage = () => {
  const {loading, handleScanOrSearch} = useUniversalSearch()
  const {t} = useTranslation('global')

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <MenuWrapperOrganism title={t('universal-search.toolbar-title')} />
    <PageContentAtom $centered>
      <Filler flexGrow={1}/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('universal-search.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{textAlign: 'center'}}>{t('universal-search.scan-anything')}</AtlasH1>
      <Filler/>
      <AtlasBodyMain style={{width: '100%', color: AtlasColor.Neutral20}}>{t('universal-search.type-label')}</AtlasBodyMain>
      <TextBoxMolecule 
        label={t('universal-search.type-placeholder')}
        onNewValue={handleScanOrSearch}
      />
    </PageContentAtom>
  </>
}
