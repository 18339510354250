import { atomWithStorage } from 'jotai/utils'
import { createReader } from '../../utils/atomReader'
import { type ScannedContainer } from 'client/scanner/scanner.types'
import { type ProductWithAlertInfo } from 'types/model'

export const selectedContainerAtom = atomWithStorage<ScannedContainer | undefined>('Storage:selectedContainerAtom', undefined)
export const selectedContainerReader = createReader(selectedContainerAtom)

export const selectedProductsAtom = atomWithStorage<ProductWithAlertInfo[]>('Storage:selectedProductsAtom', [])
export const selectedProductsReader = createReader(selectedProductsAtom)
