import { useScanner } from '../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGoToContainerDetail } from './container-detail/ContainerDetail.navigator'
import { getContainerFromScan } from 'client/scanner/scanner.client'
import { getContainersInPreStorage } from './Storage.client'
import { toast } from 'react-toastify'
import { type ContainerWithAlerts } from './Storage.types'
import { useAtom } from 'jotai'
import { selectedContainerAtom } from './Storage.state'

export const useStorageHomepage = () => {
  const { t } = useTranslation('global')
  const containerDetail = useGoToContainerDetail()
  const { keys, getScan } = useScanner()
  const [containers, setContainers] = useState<ContainerWithAlerts[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [, setSelectedContainer] = useAtom(selectedContainerAtom)

  const getContainers = async () => {
    setIsLoading(true)
    try {
      const containers = await getContainersInPreStorage()
      setContainers(containers)
    } finally {
      setIsLoading(false)
    }
  }

  // On init
  useEffect(() => {
    getContainers().catch((e) => {
      toast.error((e as Error).message)
    })
  }, [])

  // On scan
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getContainerFromScan(scanned)
        .then((c) => {
          if (c.location.type !== 'PreStorage') {
            toast.error(t('storage-home.invalid-scan', { scanned }))
            return
          }
          setSelectedContainer(c)
          containerDetail.go(scanned)
        })
        .catch(() => {
          toast.error(t('storage-container-detail.bad-request', { scanned }))
        })
        .finally(() => { setIsLoading(false) })
    }
  }, [keys, getScan])

  return {
    containers,
    isLoading
  }
}
