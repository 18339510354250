import { useNavigate } from 'react-router-dom'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'

export const MOVE_INVENTORY_ITEMS = '/transfer/:origin/to/:destination'

export const useGoToMoveInventoryItemsNavigator = () => {
  const navigate = useNavigate()

  return {
    go: ({
      origin,
      destination
    }: {
      origin: ScannedLocation | ScannedContainer
      destination: ScannedLocation | ScannedContainer
    }) => {
      const textOrigin = origin.type === ScanType.Location ? origin.location.name : origin.container.name
      const textDestination = destination.type === ScanType.Location ? destination.location.name : destination.container.name
      navigate(MOVE_INVENTORY_ITEMS.replace(':origin', textOrigin).replace(':destination', textDestination))
    }
  }
}
