import { useScanner } from '../../../hooks/scanner.hook'
import { useGoToMoveOptionsNavigator } from '../move-options/MoveOptions.navigator'
import { useEffect, useState } from 'react'
import { Global } from '../../../utils/global'
import { manageError } from '../../../utils/errors/error.handler'
import { validateOrigin } from './ScanOrigin.validator'
import { useGoToChooseContainerNavigator } from '../choose-container/ChooseContainer.navigator'
import { useAtom } from 'jotai'
import { transferHasLooseProducts, transferLooseProductsAtom, transferOriginInfoAtom } from '../transfer.state'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import { getProducts } from '../../../client/inventory/inventory.client'
import { ScanType } from '../../../client/scanner/scanner.types'
import { LocationType, LocationZone } from '../../../client/types'
import { findProductsInfo } from '../../../client/products/products.client'
import { type InventoryProduct, type ProductInfo } from '../../../types/model'
import { type ProductResponse } from '../../../client/inventory/inventory.types'

export const useScanOrigin = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const moveOptions = useGoToMoveOptionsNavigator()
  const chooseContainer = useGoToChooseContainerNavigator()
  const [, setOrigin] = useAtom(transferOriginInfoAtom)
  const [, setLooseProducts] = useAtom(transferLooseProductsAtom)
  const [hasLooseProducts] = useAtom(transferHasLooseProducts)

  const [isLoading, setIsLoading] = useState(false)

  function assignLooseProducts (looseProducts: ProductResponse[], productsWithEan: ProductInfo[]) {
    setLooseProducts(looseProducts.map<InventoryProduct>((p) => {
      const product = productsWithEan.find(it => it.ean === p.productEan)
      if (product == null) {
        throw Error('Error reading product information')
      }
      return {
        productId: product.id,
        productEan: product.ean,
        productSku: product.sku,
        productName: product.name,
        productImage: product.image,
        byWeight: product.isWeighable,
        stock: p.stock,
        country: p.country,
        warehouse: p.warehouse
      }
    }))
  }

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getLocationOrContainerFromScan(scanned)
        .then(async (origin) => {
          const products = await getProducts(origin.location.name)
          if (origin.location.type === LocationType.PreStorage ||
            (origin.location.type === LocationType.Storage &&
              origin.location.zone === LocationZone.Groceries)) {
            const looseProducts = products.filter((it) => it.container == null)
            if (looseProducts.length > 0) {
              const productsWithEan = await findProductsInfo(looseProducts.map(p => p.productEan))
              assignLooseProducts(looseProducts, productsWithEan)
            }
          }
          return origin
        })
        .then((origin) => validateOrigin(origin, hasLooseProducts))
        .then((origin) => {
          setOrigin(origin)
          if (origin.type === ScanType.Location && origin.container == null && origin.totalContainers > 1) {
            chooseContainer.go(origin)
          } else {
            moveOptions.go(origin)
          }
        })
        .catch(manageError(`ScanOrigin.hooks.ts#useEffect ${scanned}`))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [keys])

  return {
    isLoading,
    actions: {
      openMenu: () => {
        Global.WV.openMenu()
      }
    }
  }
}
