import {useEffect, useState} from "react";
import {onErrorToast} from "../../../onErrorToast.util";
import { ProductWithInfo } from "../universal-search.types";
import { useAtom } from "jotai";
import { universalSearchContainerAtomReader, universalSearchLocationAtomReader } from "../universal-search.state";
import { useNavigate } from "react-router-dom";
import { getProducts } from "client/inventory/inventory.client";
import { findProductsInfo } from "client/products/products.client";
import { UomKey } from "client/types";

export function useUniversalSearchContainer() {
  const [container] = useAtom(universalSearchContainerAtomReader)
  const [products, setProducts] = useState<ProductWithInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    getProducts(container.name)
    .then(async (products) => {
      const tempProducts = products.length > 0 ? await findProductsInfo(products.map((it) => it.productEan)) : []
      return products.map((it) => {
        const product = tempProducts.find((p) => p.ean === it.productEan)
        let productWithExtraInfo: ProductWithInfo = {...it, productName: '', productImage: '', isWeighable: false }
        if (product != null) {
          productWithExtraInfo.productName = product.name as string
          productWithExtraInfo.productImage = product.image as string
          productWithExtraInfo.isWeighable = product.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
        }
        return productWithExtraInfo
      })
    })
    .then(e => setProducts(e))
    .catch(e => {
      onErrorToast("Error al obtener productos en contenedor: " + e.message)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [])
  
  return { 
    container,
    products,
    loading,
    goBack: () => navigate(-1)
  }
}
