import { useNavigate } from 'react-router-dom'
import { type InventoryProduct } from '../../../types/model'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'

export const MOVE_ONE_CONFIGURATION = '/transfer/:origin/move-one-product/:ean'

export const useMoveOneNavigator = () => {
  const navigate = useNavigate()
  return {
    go: ({
      origin,
      item
    }: {
      origin: ScannedLocation | ScannedContainer
      item: InventoryProduct
    }) => {
      const text = origin.type === ScanType.Location ? origin.location.name : origin.container.name
      navigate(MOVE_ONE_CONFIGURATION.replace(':origin', text).replace(':ean', item.productEan))
    }
  }
}
