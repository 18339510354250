import { InventoryProduct, type ProductInfo } from '../../types/model'
import { productGatewayAxiosInstance } from '../../utils/op-product-gateway.axios.instance'
import { type ProductDetailsResponse } from './products.types'
import { axiosInstance } from '../../utils/axios.instance'
import { ProductResponse } from 'client/inventory/inventory.types'

export const findProductInfo = async (ean: string): Promise<ProductInfo> => {
  return await productGatewayAxiosInstance().get<ProductInfo>(`/v1/products/${ean}`)
    .then((res) => res.data)
}

export const findProductsInfo = async (eans: string[]): Promise<ProductInfo[]> => {
  return await productGatewayAxiosInstance()
    .get<ProductInfo[]>('/v1/products/multiple/eans', { params: { n: eans.join(',') } })
    .then((res) => res.data)
}

// TODO: move to productGatewayAxiosInstance
export const getProductDetailFromEan = async (ean: string) => {
  const axios = axiosInstance()
  return await axios.get<ProductDetailsResponse>(`/products/${ean}/details`)
    .then(e => e.data)
}

export const mapProductsWithInfo = async (products: ProductResponse[]) =>  {
  const productsWithEan = await findProductsInfo(products.map(p => p.productEan))
  return products.map<InventoryProduct>((p) => {
    const product = productsWithEan.find(it => it.ean === p.productEan)
    if (product == null) {
      throw Error('Error reading product information')
    }
    return {
      productId: product.id,
      productEan: product.ean,
      productSku: product.sku,
      productName: product.name,
      productImage: product.image,
      byWeight: product.isWeighable,
      stock: p.stock,
      country: p.country,
      warehouse: p.warehouse
    }
  })
}