import { PageLayoutAtom } from '@justomx/atlas-components'
import { createGlobalStyle, StyleSheetManager } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './pages/router'
import i18n from 'i18next'
import {
  I18nextProvider,
  initReactI18next,
  useTranslation
} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import esGlobal from './i18n/es/global.json'
import brGlobal from './i18n/pt_BR/global.json'
import { useEffect, useState } from 'react'
import './analytics/analytics.utils'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { isProduction } from 'utils/environment'
import {
  getCurrentCountry,
  getCurrentUserId,
  getCurrentWarehouse,
  getUser
} from 'client/utilities.client-helper'

export const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: isProduction()
    ? 'key_prod_2e5039e3df31016f'
    : 'key_dev_dc26a16e05a761c4',
  enableDevMode: true
})

gb.init().catch((err) => {
  console.error(err)
})

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    lng: 'es',
    resources: {
      es: { global: esGlobal },
      pt: { global: brGlobal }
    }
  })
  .catch((err) => {
    console.error(err)
  })

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* Atlas/Body Titles */
    font-style: normal;
    font-weight: 400;
  }
`

const countryToLanguage: Record<string, string> = {
  mx: 'es',
  br: 'pt',
  pe: 'es'
}

declare global {
  interface Window {
    WVMetadata?: Record<string, string>
  }
}

const defaultLanguage = 'es'

// eslint-disable-next-line @typescript-eslint/space-before-function-paren
export function App() {
  const { i18n } = useTranslation('global')
  const [metadata, setMetadata] = useState(window.WVMetadata)

  useEffect(() => {
    const warehouse = getCurrentWarehouse()
    const country = getCurrentCountry()
    const user = getUser()
    
    let gbAttributes
    
    if(user) {
      gbAttributes = { warehouseCode: warehouse, country, email: user.username }
    }
    else{
      const userId = getCurrentUserId()
      gbAttributes = { warehouseCode: warehouse, country, userId }
    }

    gb.setAttributes(gbAttributes)
      .then(() => {
        console.log('Attributes have been modified in Growthbook', gbAttributes)
      })
      .catch((err) => {
        console.error(err)
      })

    const lang = countryToLanguage[country.toLowerCase()] ?? defaultLanguage
    i18n.changeLanguage(lang).catch((err) => {
      console.error(err)
    })
  }, [metadata])

  useEffect(() => {
    const autodetectMetadataChangeId = setInterval(() => {
      setMetadata(window.WVMetadata)
    }, 1_000)
    return () => {
      clearInterval(autodetectMetadataChangeId)
    }
  }, [metadata])

  return (
    <>
    <head>
      <meta name="robots" content="noindex, nofollow" />
    </head>
    <GrowthBookProvider growthbook={gb}>
      <I18nextProvider i18n={i18n}>
        <StyleSheetManager>
          <GlobalStyle />
          <PageLayoutAtom>
            <RouterProvider router={router} />
          </PageLayoutAtom>
          <ToastContainer
            theme="dark"
            position="bottom-center"
            toastClassName="toastErrorClass"
          />
        </StyleSheetManager>
      </I18nextProvider>
    </GrowthBookProvider>
    </>
  )
}
