import { useNavigate } from 'react-router-dom'
import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import {
  transferDestinationInfoAtom,
  transferOptionSelectedReader,
  transferOriginInfoReader
} from '../transfer.state'
import { useAtom } from 'jotai/index'
import { manageError } from '../../../utils/errors/error.handler'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import { validationDestination } from './ScanDestination.validator'
import { useGoToMoveInventoryItemsNavigator } from '../move-all/MoveAll.navigator'

export const useScanDestination = () => {
  const navigate = useNavigate()
  const {
    keys,
    getScan
  } = useScanner()

  const [, setDestination] = useAtom(transferDestinationInfoAtom)
  const [origin] = useAtom(transferOriginInfoReader)
  const [option] = useAtom(transferOptionSelectedReader)

  const [isLoading, setIsLoading] = useState(false)

  const moveInventoryProducts = useGoToMoveInventoryItemsNavigator()

  /* Scan destination */
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      setIsLoading(true)
      getLocationOrContainerFromScan(scanned)
        .then((destination) => {
          validationDestination({
            origin,
            destination,
            flow: option
          })
          return destination
        })
        .then((destination) => {
          setDestination(destination)
          return destination
        })
        .then((destination) => {
          moveInventoryProducts.go({
            origin,
            destination
          })
        })
        .catch(manageError(`ScanDestination.hooks.ts#useEffect ${scanned}`))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [keys])

  return {
    origin,
    isLoading,
    actions: {
      back: () => {
        navigate(-1)
      }
    }
  }
}
