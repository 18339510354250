import { useTranslation } from 'react-i18next'
import { useScanOrigin } from './ScanOrigin.hook'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom
} from '@justomx/atlas-components'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'
import LoaderAtom from 'components/atoms/Loader.atom'

export const ScanOriginPage = () => {
  const {
    isLoading
  } = useScanOrigin()
  const { t } = useTranslation('global')

  if (isLoading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <MenuWrapperOrganism title={t('scan-origin.toolbar-title')} />
    <PageContentAtom $centered>
      <Filler />
      <Icon src={Icons.ScanIcon} />
      <AtlasBodyTitle>{t('scan-origin.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('scan-origin.location')} <br />o <br />{t('scan-origin.container')}
      </AtlasH1>
      <AtlasBodyTitle>{t('scan-origin.use-your-scanner')}</AtlasBodyTitle>
      <Filler />
    </PageContentAtom>
  </>
}
