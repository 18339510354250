import { AtlasBodyMain, AtlasBodySubtitle, AtlasColor, CardRow, ImageThumbnailComponent, StackAtom } from "@justomx/atlas-components";
import { FlexRowAtom } from "components/atoms/FlexRow.atom";
import { ProductWithInfo } from "../universal-search.types";

interface ProductInfoProps {
  product: ProductWithInfo
}

const ProductInfo = ({product}: ProductInfoProps) => {
  return(<CardRow style={{alignItems: 'flex-start'}}>
    <ImageThumbnailComponent 
      size={60}
      src={product.productImage}
      style={{ alignSelf: 'self-start' }}
    />
    <StackAtom gap={12}>
      <AtlasBodySubtitle>{product.productName}</AtlasBodySubtitle>
      <FlexRowAtom style={{alignItems: 'center', gap:4, color: AtlasColor.Neutral40}}>
        <AtlasBodyMain>UPC: {product.productEan}</AtlasBodyMain>
        <AtlasBodyMain>|</AtlasBodyMain>
        <AtlasBodyMain>SKU: {product.productSku}</AtlasBodyMain>
      </FlexRowAtom>
    </StackAtom>
  </CardRow>
  )
}

export default ProductInfo;