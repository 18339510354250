import { AtlasBodySubtitle } from '@justomx/atlas-components'
import { InventoryProductCardMolecule } from 'components/molecules/v2/InventoryProductCard.molecule'
import { type ProductWithAlertInfo } from 'types/model'

interface GroupOfProductProps {
  title: string
  variant?: 'urgent' | 'critical' | 'warning'
  products: ProductWithAlertInfo[]
  selectable?: boolean
  onSelect?: (p: ProductWithAlertInfo) => void
  selectedInventoryProducts?: ProductWithAlertInfo[]
}

function formatStock (stock: number, isWeighable: boolean): string {
  if (isWeighable) {
    return `${stock} g`
  } else if (stock === 1) {
    return `${stock} pieza`
  } else {
    return `${stock} piezas`
  }
}

export const GroupOfProduct = ({
  title,
  products,
  selectable,
  onSelect,
  selectedInventoryProducts,
  variant
}: GroupOfProductProps) => {
  if (products == null || products.length === 0) {
    return null
  }
  return (
    <>
      <AtlasBodySubtitle>{title}</AtlasBodySubtitle>
      {products.map((p) => (
        <InventoryProductCardMolecule
          selectable={selectable}
          key={p.ean + p.stock}
          product={p}
          onClick={() => onSelect && onSelect(p)}
          selected={selectedInventoryProducts?.some(s => s.sku === p.sku)}
          subtitle={`Cantidad total: ${formatStock(p.stock, p.isWeighable)}`}
          variant={variant}
        />
      ))
      }
    </>
  )
}
