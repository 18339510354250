import { type ScannedLocation } from 'client/scanner/scanner.types'
import { LocationZone } from 'client/types'

export const validateDestination = (destination: ScannedLocation, storeContainer: boolean) => {
  if (destination.location.type !== 'Storage') {
    throw Error(
      'No se puede almacenar un contenedor a una ubicación que no es de almacenamiento.'
    )
  }
  if (storeContainer) {
    console.log({ destination, storeContainer })
    if (destination.location.zone !== LocationZone.Groceries) {
      throw Error(
        'No se puede almacenar un contenedor en una ubicación que no permite contenedores.'
      )
    }
    if (destination.location.classification !== 'Unit') {
      throw Error(
        'No se puede almacenar un contenedor a una ubicación de piso.'
      )
    }
    if (destination.totalContainers > 0) {
      throw Error(
        'No se puede almacenar un contenedor a una ubicación que ya tiene un contenedor.'
      )
    }
  }

  if (!storeContainer && destination.location.zone === LocationZone.Groceries) {
    console.log({ destination, storeContainer })
    throw Error(
      'No se puede almacenar productos a una ubicación que solamente permite contenedores.'
    )
  }
  return destination
}
