import { transferOriginInfoReader, transferSelectedItemsAtom } from '../transfer.state'
import { useAtom } from 'jotai/index'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScanDestinationNavigator } from '../scan-destination/ScanDestination.navigator'

export const useMoveOneConfiguration = () => {
  const navigate = useNavigate()
  const scanDestinationNavigator = useScanDestinationNavigator()

  const [quantity, setQuantity] = useState<string>('')

  const [origin] = useAtom(transferOriginInfoReader)
  const [selectedItems, setSelectedItems] = useAtom(transferSelectedItemsAtom)
  const inventoryProduct = selectedItems[0]

  return {
    origin,
    inventoryProduct,
    canConfirm: () => {
      const qtyInString = quantity.trim()
      if (/\d/.test(qtyInString)) {
        const qty = Number(qtyInString)
        return qty > 0 && qty <= inventoryProduct.stock
      }
      return false
    },
    actions: {
      confirm: () => {
        inventoryProduct.quantity = +quantity
        setSelectedItems([inventoryProduct])
        scanDestinationNavigator.go(origin)
      },
      goBack: () => {
        navigate(-1)
      },
      setQuantity
    }
  }
}
