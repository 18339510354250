import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom
} from '@justomx/atlas-components'
import { useFastTraslation } from './FastTraslation.hook'
import { onErrorToast } from '../../onErrorToast.util'
import { useTranslation } from 'react-i18next'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'

export const FastTraslationPage = () => {
  const { t } = useTranslation('global')

  useFastTraslation((errorText) => {
    onErrorToast(t(errorText))
  })

  return (
    <>
      <MenuWrapperOrganism title={t('fast-traslation.toolbar-title')} />
      <PageContentAtom $centered>
        <Filler flexGrow={1} />
        <Icon src={Icons.ScanIcon} />
        <AtlasBodyTitle>{t('fast-traslation.scan')}</AtlasBodyTitle>
        <AtlasH1 style={{ textAlign: 'center' }}>LPN</AtlasH1>
        <AtlasBodyTitle>{t('fast-traslation.use-your-scanner')}</AtlasBodyTitle>
        <Filler />
      </PageContentAtom>
    </>
  )
}
