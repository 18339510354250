import Axios, { type AxiosInstance } from 'axios'
import { isProduction } from '../../utils/environment'
import {
  type CommonHeaders,
  getCommonHeaders
} from '../../client/utilities.client-helper'

let axios

function configureAxios () {
  if (isProduction()) {
    return Axios.create({
      baseURL: 'https://api.justo.cloud/op-storage-manager/'
    })
  }

  return Axios.create({
    baseURL: 'https://stag.justo.cloud/op-storage-manager/'
  })
}

function getAxiosInstance (): AxiosInstance {
  if (!axios) {
    axios = configureAxios()

    axios.interceptors.request.use((config) => {
      const headers: CommonHeaders = getCommonHeaders()
      Object.keys(headers).forEach((e) => config.headers.set(e, headers[e]))
      return config
    })
  }

  return axios
}

export const storageAxiosInstance = getAxiosInstance
