import { ScanContainerLocationPage } from './scan-container-location/ScanContainerLocation.page'
import { type RouteObject } from 'react-router-dom'
import { InventoryProductListPage } from './product-list/InventoryProductList.page'
import { InventoryProductFixPage } from './product-fix/InventoryProductFix.page'
import { ScanProductInContainerPages } from './scan-product-in-container/ScanProductInContainer.pages'
import { INVENTORY_PRODUCT_LIST_URL } from './product-list/InventoryProductList.navigator'
import { INVENTORY_PRODUCT_FIX_URL } from './product-fix/InventoryProductFix.navigator'
import { SCAN_CONTAINER_LOCATION } from './scan-container-location/ScanContainerLocation.navigator'
import { SCAN_PRODUCT_IN_CONTAINER } from './scan-product-in-container/ScanProductInContainer.navigator'
import { CREATE_PRODUCT_URL } from './create-product/CreateProduct.navigator'
import { CreateProductPage } from './create-product/CreateProduct.page'

export const inventoryFixRoutes: RouteObject[] = [
  {
    id: '(inventory-fix) Scan container or location',
    path: SCAN_CONTAINER_LOCATION,
    element: <ScanContainerLocationPage/>
  },
  {
    id: '(inventory-fix) Product list',
    path: INVENTORY_PRODUCT_LIST_URL,
    element: <InventoryProductListPage/>
  },
  {
    id: '(inventory-fix) Scan product',
    path: SCAN_PRODUCT_IN_CONTAINER,
    element: <ScanProductInContainerPages/>
  },
  {
    id: '(inventory-fix) Product detail',
    path: INVENTORY_PRODUCT_FIX_URL,
    element: <InventoryProductFixPage/>
  }, {
    id: '(inventory-fix) Product creation',
    path: CREATE_PRODUCT_URL,
    element: <CreateProductPage />
  }
]
