import { type ContainerResponse, type Page } from './location.types'
import { msLocationAxiosInstance } from '../../utils/ms-location.axios.instance'

export async function getContainersInLocation (location: string): Promise<ContainerResponse[]> {
  return await msLocationAxiosInstance().get<Page<ContainerResponse>>('/v1/containers', {
    params: {
      pageSize: 1_000,
      location
    }
  }).then((res) => res.data.data)
}
