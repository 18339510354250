import { AtlasBodySubtitle, AtlasBodyTitle, AtlasColor, CardRow, Icon, Icons } from '@justomx/atlas-components'
import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { type ContainerResponse } from '../../client/location/location.types'

interface ContainerMoleculeProp {
  data: Required<ContainerResponse>
}

export const ContainerMolecule = ({
  data
}: ContainerMoleculeProp) => {
  return (
    <>
      <CardRow style={{ justifyContent: 'space-between' }}>
        <FlexRowAtom style={{ gap: 8 }}>
          <Icon src={Icons.IcPallet} size={18} />
          <AtlasBodyTitle
            color={AtlasColor.Neutral00}
            data-container={data.name}
          >
            {data.name}
          </AtlasBodyTitle>
        </FlexRowAtom>
      </CardRow>
      <CardRow>
        <Icon src={Icons.IcUbicacionIcon} size={18} />
        <AtlasBodySubtitle
          color={AtlasColor.Neutral20}
          data-location={data.location.name}
        >
          {data.location.name}
        </AtlasBodySubtitle>
      </CardRow>
    </>
  )
}
