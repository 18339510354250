import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGoToStorageScanDestinationDetail } from '../scan-destination/StorageScanDestination.navigator'
import { useEffect, useState } from 'react'
import { type ProductWithAlertInfo } from 'types/model'
import { useAtom } from 'jotai'
import { getProducts } from 'client/inventory/inventory.client'
import { toast } from 'react-toastify'
import { getActiveProductsFromSuggestion } from 'client/alerts/alerts.client'
import { findProductsInfo } from 'client/products/products.client'
import { useGoToStorage } from '../Storage.navigator'
import { selectedProductsAtom } from '../Storage.state'

export const useContainerDetail = () => {
  const { t } = useTranslation('global')

  const storagePage = useGoToStorage()
  const scanDestination = useGoToStorageScanDestinationDetail()

  const [selected, setSelected] = useState<ProductWithAlertInfo[]>([])
  const [products, setProducts] = useState<ProductWithAlertInfo[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [, setSelectedInventoryProducts] = useAtom(selectedProductsAtom)

  const params = useParams()
  const containerName: string = params.containerName ?? 'INVALID_CONTAINER_NAME'

  // On Init: find products and alerts
  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      getActiveProductsFromSuggestion(containerName),
      getProducts(containerName)
    ])
      .then(async ([alerts, products]) => {
        if (products.length > 0) {
          const res = await findProductsInfo(products.map((p) => p.productEan))
          const mappedProducts = res.map((it): ProductWithAlertInfo => {
            const stock = products.find((p) => p.productEan === it.ean)?.stock ?? 0
            const alert = alerts.find((a) => a.productEan === it.ean)?.level ?? 'None'
            return { ...it, stock, alert }
          })
          setProducts(mappedProducts)
        } else {
          storagePage.go()
          toast.error(t('alerts.scan-product.error-no-products'))
        }
      })
      .catch((err: Error) => {
        toast.error(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    products,
    selectedInventoryProducts: selected,
    isLoading,
    actions: {
      continue: () => {
        setSelectedInventoryProducts(selected)
        scanDestination.go(
          containerName,
          products.length === selected.length
        )
      },
      toggleInventoryProduct: (inventoryProduct: ProductWithAlertInfo) => {
        if (selected.find((e) => e.ean === inventoryProduct.ean)) {
          setSelected((s) => s.filter((x) => x.ean !== inventoryProduct.ean))
        } else {
          setSelected((s) => s.concat(inventoryProduct))
        }
      },
      selectAllInventoryProducts: () => {
        setSelected(products)
      },
      clearSelectedInventoryProducts: () => {
        setSelected([])
      }
    }
  }
}
