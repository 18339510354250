import {
  AtlasBodySubtitle,
  AtlasBodyTitle,
  AtlasH1,
  CardComponent,
  CardRow,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { Global } from '../../../utils/global'
import { useFastTraslationScanDestinationHook } from './FastTraslationScanDestination.hook'
import { onErrorToast } from '../../../onErrorToast.util'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'

interface LocationsCardProps {
  label: string
  locations: string[]
}

export const LocationsCard = ({ locations, label }: LocationsCardProps) => {
  if (!locations.length) {
    return null
  }

  return (
      <CardComponent>
          <AtlasBodySubtitle>{label}</AtlasBodySubtitle>
          {locations.map(location => <CardRow key={location}>
              <Icon src={Icons.IcUbicacionIcon} size={18}/>
              <AtlasBodySubtitle>{location}</AtlasBodySubtitle>
          </CardRow>)}
      </CardComponent>
  )
}

export const FastTraslationScanDestinationPage = () => {
  const {
    currentLocations,
    suggestedLocations,
    showLoader
  } = useFastTraslationScanDestinationHook(onErrorToast)
  const { t } = useTranslation('global')

  if (showLoader) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return (
    <>
        <MenuWrapperOrganism title={t('fast-scan-dest.toolbar-title')} />
        <PageContentAtom $centered>
            <Filler flexGrow={1}/>
            <Icon src={Icons.ScanIcon}/>
            <AtlasBodyTitle>{t('fast-scan-dest.scan')}</AtlasBodyTitle>
            <AtlasH1 style={{ textAlign: 'center' }}>{t('fast-scan-dest.picking-location')}</AtlasH1>
            <AtlasBodyTitle>{t('fast-scan-dest.use-your-scanner')}</AtlasBodyTitle>
            <Filler/>
            <LocationsCard label={t('fast-scan-dest.suggested-locations')} locations={suggestedLocations} />
            <LocationsCard label={t('fast-scan-dest.current-locations')} locations={currentLocations} />
        </PageContentAtom>
    </>
  )
}
