import { useNavigate } from 'react-router-dom'

export const STORAGE_SCAN_DESTINATION_URL = '/to-storage/:containerName/send'

export const useGoToStorageScanDestinationDetail = () => {
  const navigate = useNavigate()

  return {
    go: (containerName: string, moveAll: boolean) => {
      const url = STORAGE_SCAN_DESTINATION_URL.replace(
        ':containerName',
        containerName
      )
      navigate(`${url}?moveAll=${moveAll}`)
    }
  }
}
