import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useScanner } from 'hooks/scanner.hook'
import { Global } from 'utils/global'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useReverseLogisticsRegisterWasteOrMissing } from '../register-waste-or-missing/RegisterWasteOrMissing.navigator'
import { getPendingDetails, getPendings } from '../reverse-logistics.client'
import { TareOrProduct } from 'types/model'
import { reverseLogisticsScannedSuppliesAtom } from '../reverse-logistics.state'
import { useReverseLogisticsScanProduct } from '../scan-product/ScanProduct.navigator'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { getSupplyFromScan } from 'client/scanner/scanner.client'
import { useReverseLogisticsNavigator } from '../ReverseLogistics.navigator'
import { Analytics } from 'analytics/analytics.events'
import { getCurrentWarehouse } from 'client/utilities.client-helper'

export const useScanSupplies = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const {
    dialogRef,
    open: openSearchDialog,
  } = useConfirmationDialog()
  const { go } = useReverseLogisticsRegisterWasteOrMissing()
  const [data, setData] = useState<TareOrProduct[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedSupplies, setSelectedSupplies] = useAtom(reverseLogisticsScannedSuppliesAtom)
  const [scannedSupplies, setScannedSupplies] = useState([] as TareOrProduct[])
  const reverseLogisticsScanProduct = useReverseLogisticsScanProduct()
  const { t } = useTranslation('global')
  const warehouse = getCurrentWarehouse()

  useEffect(() => {
    setScannedSupplies([])
    setLoading(true)
    getPendings()
      .then((data) => {
        setData(data.filter((item) => item.status !== 'Completed'))
      })
      .catch((error) => {
        console.log(error)
        toast.error('Error al obtener listado de taras y productos')
      })
      .finally(() => { 
        setLoading(false)
      })
  }, [])

  const searchSupply = async (scanned: string) => {
    setLoading(true)
    getSupplyFromScan(scanned)
      .then(() => {
        const selected = data.find((item) => item.tara?.barcode === scanned)
        if(selected){
          getPendingDetails(selected.id)
          .then((res) => {
            if(scannedSupplies.length < 10){
              const isAlreadyAdded = scannedSupplies.some((supply) => supply.tara?.barcode === scanned)
              if (!isAlreadyAdded) {
                const supplyWithDetails = { ...selected, products: res.products }
                setSelectedSupplies((prevSupplies) => {
                  if (scannedSupplies.length === 0) {
                    return [supplyWithDetails];
                  }
                  return [...prevSupplies, supplyWithDetails];
                });
                setScannedSupplies((prevSupplies) => [...prevSupplies, supplyWithDetails]);
              }
              else {
                toast.error(`La tara ${scanned} ya fue escaneada, selecciona otra.`);
              }
            }
            else{
              toast.error(t('reverse-logistics.scan-supplies.max-scanned'))
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error('Error al obtener detalles de tara')
          })
        }
        else{
          toast.error(t('reverse-logistics.tare-not-found'))
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(t('reverse-logistics.tare-not-valid'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const scanned = getScan()
    if(scanned){
      searchSupply(scanned)
    }
  }, [keys])

  return {
    scannedSupplies,
    selectedSupplies,
    loading,
    actions: {
      openSearchDialog,
      goToWasteOrMissingPage: go,
      continue: () => reverseLogisticsScanProduct.go(),
      continueWithLastList: () => {
        Analytics.reverseLogistics.ClickHistoryList(warehouse)
        reverseLogisticsScanProduct.go()
      },
      delete: (supplyId: string) => {
        setScannedSupplies(scannedSupplies.filter(s => s.id !== supplyId))
      },
      searchSupply
    },
    dialogRef
  }
}
