import { AtlasColor, CardComponent, CardRow, StackAtom, Divider, AtlasBodyMain} from "@justomx/atlas-components";
import ProductInfo from "./product-info.component";
import { useTranslation } from "react-i18next";
import { ProductWithInfo } from "../universal-search.types";

type ProductWithStockProps = {
  product: ProductWithInfo
}

export function ProductWithStock({product}: ProductWithStockProps) {
  const {t} = useTranslation('global')
  return <CardComponent>
    <StackAtom style={{flexDirection: 'column', alignItems: 'baseline'}} gap={12}>
      <ProductInfo product={product}/>
      <Divider/>
      <CardRow style={{ width: '100%', justifyContent: 'space-between'}}>
        <AtlasBodyMain color={AtlasColor.Neutral40}>{t('universal-search.quantity')}</AtlasBodyMain>
        <AtlasBodyMain>{product.stock} {product.isWeighable ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
      </CardRow>
    </StackAtom>
  </CardComponent>;
}

