import { atomWithStorage } from 'jotai/utils'
import { type InventoryProduct } from '../../types/model'
import { createReader } from '../../utils/atomReader'
import { ScannedContainer, ScannedLocation } from 'client/scanner/scanner.types'

type ScannedLocationOrContainer = ScannedLocation | ScannedContainer

export const inventoryFixOriginInfoAtom = atomWithStorage<ScannedLocationOrContainer | null>('inventoryFixOriginInfoAtom', null)
export const inventoryFixOriginInfoReader = createReader(inventoryFixOriginInfoAtom)

export const inventoryProductsAtom = atomWithStorage<InventoryProduct[]>('inventoryFixProducts', [])
export const inventoryProductsReader = createReader(inventoryProductsAtom)

export const inventoryFixSelectedProductAtom = atomWithStorage<InventoryProduct>('inventoryFixSelectedProduct', {} as InventoryProduct)
export const inventoryFixSelectedProductReader = createReader(inventoryFixSelectedProductAtom)

