import { ScanOriginPage } from './scan-origin/ScanOrigin.page'
import { ScanDestinationPage } from './scan-destination/ScanDestination.page'
import { type RouteObject } from 'react-router-dom'
import { MoveOptionsPage } from './move-options/MoveOptions.page'
import { TRANSFER_MOVE_OPTIONS_URL } from './move-options/MoveOptions.navigator'
import { MOVE_INVENTORY_ITEMS } from './move-all/MoveAll.navigator'
import { MoveAllPage } from './move-all/MoveAll.page'
import { SCAN_DESTINATION_URL } from './scan-destination/ScanDestination.navigator'
import { TRANSFER_PRODUCT_LIST } from './move-product-list/MoveInventoryProductList.navigator'
import { MoveInventoryProductListPage } from './move-product-list/MoveInventoryProductList.page'
import { TRANSFER_SCAN_ORIGIN } from './scan-origin/ScanOrigin.navigator'
import { MOVE_ONE_CONFIGURATION } from './move-one-configuration/MoveOneConfiguration.navigator'
import { MoveOneConfigurationPage } from './move-one-configuration/MoveOneConfiguration.page'
import { TRANSFER_CHOOSE_CONTAINER_URL } from './choose-container/ChooseContainer.navigator'
import { ContainersPage } from './choose-container/ChooseContainer.page'

export const transferRoutes: RouteObject[] = [
  {
    id: '(transfer) Scan origin',
    path: TRANSFER_SCAN_ORIGIN,
    element: <ScanOriginPage />
  },
  {
    id: '(transfer) Option for scan',
    path: TRANSFER_MOVE_OPTIONS_URL,
    element: <MoveOptionsPage />
  },
  {
    id: '(transfer) Scan destination',
    path: SCAN_DESTINATION_URL,
    element: <ScanDestinationPage />
  },
  {
    id: '(transfer) Move all products to location',
    path: MOVE_INVENTORY_ITEMS,
    element: <MoveAllPage />
  }, {
    id: '(transfer) Move some products to location',
    path: TRANSFER_PRODUCT_LIST,
    element: <MoveInventoryProductListPage />
  },
  {
    id: '(transfer) Configure movement of one product to location',
    path: MOVE_ONE_CONFIGURATION,
    element: <MoveOneConfigurationPage />
  },
  {
    id: '(transfer) Choose Container',
    path: TRANSFER_CHOOSE_CONTAINER_URL,
    element: <ContainersPage />
  }
]
