import { useNavigate } from 'react-router-dom'
import { type ScannedContainer, type ScannedLocation } from 'client/scanner/scanner.types'

export const useGoToInventoryProductList = () => {
  const navigate = useNavigate()

  return {
    go: (locationOrContainer: ScannedLocation | ScannedContainer) => {
      const url = INVENTORY_PRODUCT_LIST_URL
      .replace(':location', locationOrContainer.location.name)
      .replace(':container', locationOrContainer.container?.name || 'null')
      navigate(`${url}?type=${locationOrContainer.type}`)
    }
  }
}

export const INVENTORY_PRODUCT_LIST_URL = '/inventory-fix/:location/:container/product'


