import { InventoryProductCardMolecule } from '../../../components/molecules/InventoryProductCard.molecule'
import { type InventoryProduct } from 'types/model'
import { useInventoryProductsList } from './InventoryProductList.hook'
import {
  AtlasBodySubtitle,
  CardComponent,
  CardRow,
  Icon,
  Icons,
  PageContentAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import './InventoryProductList.css'
import LoaderAtom from 'components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'

function formatStock (stock: number, isWeighable: boolean): string {
  if (isWeighable) {
    return `${stock} g`
  } else {
    return `${stock} ${stock > 1 ? 'piezas' : 'pieza'}`
  }
}

export const InventoryProductListPage = () => {
  const {
    actions,
    filteredProducts,
    isLoading,
    origin
  } = useInventoryProductsList()
  const { t } = useTranslation('global')

  const toInventoryProductFix = (product: InventoryProduct) => () => {
    actions.setSelectedProduct(product)
    actions.goToInventoryProductFix(product.productEan)
  }
  const toScanProduct = actions.goToScanProduct
  const back = actions.back

  if(isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <>
    <ToolbarMolecule title="Productos"
                     actionIcon={Icons.IcCancelIcon}
                     secondaryActionIcon={Icons.IcScanIcon}
                     onAction={back}
                     onSecondaryAction={toScanProduct}
                     data-test="inventory-product-list-toolbar"
    />
    <PageContentAtom>
      <CardComponent $variant="highlight" style={{ gap: 4 }}>
        {origin.container?.name && <CardRow>
          <Icon src={Icons.IcPallet} size={18} />
          <AtlasBodySubtitle>{origin.container.name}</AtlasBodySubtitle>
        </CardRow>}
        <CardRow>
          <Icon src={Icons.IcUbicacionIcon} size={18} />
          <AtlasBodySubtitle>{origin.location?.name}</AtlasBodySubtitle>
        </CardRow>
      </CardComponent>
      <TextBoxMolecule
        type="search"
        label="Buscar"
        onChange={actions.handleFilterChange} />

      {filteredProducts.length === 0 ? <InventoryProductListEmpty /> : ''}

      {filteredProducts.map(e =>
        <InventoryProductCardMolecule key={`${e.productEan}-${e.stock}`}
                                      inventoryProduct={e}
                                      onClick={toInventoryProductFix(e)}
                                      subtitle={`Cantidad total: ${formatStock(e.stock, e.byWeight)}`}
                                      data-test="product-card"
        />
      )}

    </PageContentAtom>
  </>
}

const InventoryProductListEmpty = () => {
  return (
    <div className="product-list-empty">
      <svg xmlns="http://www.w3.org/2000/svg"
           className="icon icon-tabler icon-tabler-mood-empty"
           viewBox="0 0 24 24" strokeWidth="1.5"
           stroke="currentColor" fill="none" strokeLinecap="round"
           strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
        <path d="M9 10l.01 0" />
        <path d="M15 10l.01 0" />
        <path d="M9 15l6 0" />
      </svg>
      No hay productos en esta ubicación y/o contenedor
    </div>
  )
}
