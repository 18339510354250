import { useScanner } from '../../../hooks/scanner.hook'
import { useEffect } from 'react'
import { useGoToInventoryProductList } from '../product-list/InventoryProductList.navigator'
import { manageError } from '../../../utils/errors/error.handler'
import { validateScan } from './ScanContainerLocation.validator'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import { useAtom } from 'jotai'
import { inventoryFixOriginInfoAtom } from '../InventoryFix.state'

export const useScanContainerLocation = () => {
  const {
    keys,
    getScan
  } = useScanner()
  const inventoryProductList = useGoToInventoryProductList()
  const [origin, setOrigin] = useAtom(inventoryFixOriginInfoAtom)

  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      getLocationOrContainerFromScan(scanned)
        .then(validateScan)
        .then(origin => {
          setOrigin(origin)
          inventoryProductList.go(origin)
        })
        .catch(manageError(`ScanContainerLocation.hooks.ts#useEffect ${scanned}`))
    }
  }, [keys])

}
