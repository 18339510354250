import {
  AtlasBodyMain,
  AtlasBodyTitle,
  AtlasColor,
  AtlasListAtom,
  CardComponent,
  Icon,
  Icons,
  PageContentAtom,
  SnackbarInformationPanel,
  StackAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import LoaderAtom from 'components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'
import { onErrorToast } from '../../../onErrorToast.util'
import { useContainers } from './ChooseContainer.hooks'
import ImageSingleProduct from '../../../assets/images/Utilities/Illustration_single_product.svg'
import { LocationType } from '../../../client/types'
import { type ContainerResponse } from '../../../client/location/location.types'
import { ContainerMolecule } from '../../../components/molecules/Container.molecule'

interface ContainerCardProps {
  container: Required<ContainerResponse>
}

export const ContainerCard = ({
  container
}: ContainerCardProps) => {
  return <CardComponent>
    <StackAtom>
      <ContainerMolecule data={container} />
    </StackAtom>
  </CardComponent>
}

export const ContainersPage = () => {
  const {
    origin,
    containers,
    hasLooseProducts,
    actions,
    isLoadingPage
  } = useContainers(onErrorToast)
  const { t } = useTranslation('global')
  const getItems: () => string[] = () => {
    return t('choose-container.loose-product.items', { returnObjects: true })
  }

  if (isLoadingPage) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <ToolbarMolecule title={t('choose-container.toolbar-title')}
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.back}
    />
    <PageContentAtom>
      {origin.location.type !== LocationType.Picking && hasLooseProducts && (
        <>
          <AtlasBodyTitle>{t('choose-container.location-with-loose-product')}</AtlasBodyTitle>
          <CardComponent onClick={actions.goToMoveProducts}>
            <img src={ImageSingleProduct} alt="Image showing how to move a loose product to a location" />
            <AtlasBodyTitle>{t('choose-container.loose-product.title')}</AtlasBodyTitle>
            <AtlasListAtom>
              {
                getItems().map(e => <li key={e}>
                  <AtlasBodyMain display="contents">{e}</AtlasBodyMain>
                </li>)
              }
            </AtlasListAtom>
          </CardComponent>
        </>
      )}
      <AtlasBodyTitle>{t('choose-container.containers-in-location')}</AtlasBodyTitle>
      {
        containers.map(e => (
          <ContainerCard key={e.id} container={e} />
        ))
      }
    </PageContentAtom>
    <SnackbarInformationPanel style={{
      border: '1px solid',
      backgroundColor: AtlasColor.Primary10,
      borderColor: AtlasColor.Important
    }}>
      <Icon src={Icons.ScanIcon} />
      {t('choose-container.scan-container')}
    </SnackbarInformationPanel>
  </>
}
