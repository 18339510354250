import { useTranslation } from 'react-i18next'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom
} from '@justomx/atlas-components'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'
import { useScanOrigin } from './ScanOrigin.hook'

export const ScanOriginPage = () => {
  useScanOrigin()
  const { t } = useTranslation('global')

  return <>
    <MenuWrapperOrganism title={t('inventory-waste.scan-origin.toolbar-title')} />
    <PageContentAtom $centered>
      <Filler />
      <Icon src={Icons.ScanIcon} />
      <AtlasBodyTitle>{t('scan-origin.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('scan-origin.location')} <br />o <br />{t('scan-origin.container')}
      </AtlasH1>
      <AtlasBodyTitle>{t('scan-origin.use-your-scanner')}</AtlasBodyTitle>
      <Filler />
      <Filler />
    </PageContentAtom>
  </>
}
