import { useNavigate } from 'react-router-dom'

export const useGoToStorage = () => {
  const navigate = useNavigate()

  return {
    go: () => {
      navigate(STORAGE_URL)
    }
  }
}

export const STORAGE_URL = '/to-storage/'
