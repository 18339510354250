import {useNavigate} from 'react-router-dom'
import { type ScanResponse } from 'client/scanner/scanner.types'

interface Go {
  origin: ScanResponse
  ean: string
}

export const useGoToInventoryProductFix = () => {
  const navigate = useNavigate()

  return {
    go: ({ origin, ean }: Go) => {
      const url  =  INVENTORY_PRODUCT_FIX_URL
      .replace(':location', origin.location?.name as string)
      .replace(':container', origin.container?.name || 'null')
      .replace(':ean', ean)
      
      navigate(url)
    }
  }
}

export const INVENTORY_PRODUCT_FIX_URL = '/inventory-fix/:location/:container/product/:ean/'
