import { InventoryProductCardMolecule } from 'components/molecules/InventoryProductCard.molecule'
import { InventoryProductFixQuantityComponent } from 'components/molecules/InventoryProductFixQuantity'
import { useInventoryProductFix } from './InventoryProductFix.hooks'
import { Filler, Icons, PageContentAtom, PrimaryButtonAtom, ToolbarMolecule } from '@justomx/atlas-components'

function formatStock (stock: number, isWeighable: boolean): string {
  if (isWeighable) {
    return `${stock} g`
  } else {
    return `${stock} ${stock > 1 ? 'piezas' : 'pieza'}`
  }
}

export const InventoryProductFixPage = () => {
  const {
    value,
    canConfirm,
    origin,
    actions,
    loading
  } = useInventoryProductFix()
  const onChangeQuantity = (value: string) => { actions.setQuantity(value) }

  return <>
        <ToolbarMolecule title='Reajustar productos'
                         actionIcon={Icons.ArrowBack}
                         onAction={actions.goBack}
                         data-test='product-fix-toolbar'/>
        <PageContentAtom>
            <InventoryProductCardMolecule
              inventoryProduct={value}
              subtitle={`Cantidad total: ${formatStock(value.stock, value.byWeight)}`}
              data-test={'product-card'}
            />
            <InventoryProductFixQuantityComponent
                inventoryProduct={value}
                onChange={onChangeQuantity}
                location={origin?.location?.name as string}
                label='Cantidad'
            />
            <Filler/>
            <PrimaryButtonAtom disabled={loading || canConfirm.disabled }
                               onClick={actions.confirm}>Confirmar</PrimaryButtonAtom>
        </PageContentAtom>
    </>
}
