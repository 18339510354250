import { useScanDestinationNavigator } from '../scan-destination/ScanDestination.navigator'
import { useNavigate } from 'react-router-dom'
import { type TransferOptionSelected } from '../../../components/molecules/MoveProductsSelector.molecule'
import {
  transferHasLooseProducts,
  transferItemsAtom,
  transferOptionSelectedAtom,
  transferOriginInfoReader
} from '../transfer.state'
import { useAtom } from 'jotai/index'
import { getProducts } from '../../../client/inventory/inventory.client'
import { useEffect, useState } from 'react'
import { handleError } from '../../receipt/client/Receipt.error'
import { useMoveProductListNavigator } from '../move-product-list/MoveInventoryProductList.navigator'
import { findProductsInfo } from '../../../client/products/products.client'
import type { InventoryProduct } from '../../../types/model'
import { type ProductResponse } from '../../../client/inventory/inventory.types'

export const useMoveOptions = () => {
  const navigate = useNavigate()

  const [origin] = useAtom(transferOriginInfoReader)
  const [, setOption] = useAtom(transferOptionSelectedAtom)
  const [hasLooseProducts] = useAtom(transferHasLooseProducts)
  const [products, setProducts] = useAtom(transferItemsAtom)

  const [options, setOptions] = useState<TransferOptionSelected[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const scanDestinationNavigator = useScanDestinationNavigator()
  const productListNavigator = useMoveProductListNavigator()

  async function mapProducts (products: ProductResponse[]) {
    const productsWithEan = await findProductsInfo(products.map(p => p.productEan))
    return products.map<InventoryProduct>((p) => {
      const product = productsWithEan.find(it => it.ean === p.productEan)
      if (product == null) {
        throw Error('Error reading product information')
      }
      return {
        productId: product.id,
        productEan: product.ean,
        productSku: product.sku,
        productName: product.name,
        productImage: product.image,
        byWeight: product.isWeighable,
        stock: p.stock,
        country: p.country,
        warehouse: p.warehouse
      }
    })
  }

  /* Getting products */
  useEffect(() => {
    setIsLoading(true)
    getProducts(origin.container?.name ?? origin.location.name)
      .then(async (products) => {
        return await mapProducts(products)
      })
      .then((products) => {
        setProducts(products)
      })
      .catch((err) => {
        handleError((err as Error).message ?? 'Error getting products')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  /* Options active */
  useEffect(() => {
    const tmp: TransferOptionSelected[] = []
    if (products.length > 0) {
      tmp.push('move-products')
    }
    if (origin.container) {
      tmp.push('move-container')
    }
    if (hasLooseProducts) {
      tmp.push('move-loose-products')
    }
    setOptions(tmp)
  }, [products])

  return {
    isLoading,
    origin,
    options,
    actions: {
      back: () => {
        navigate(-1)
      },
      onClickGo: (option: TransferOptionSelected) => () => {
        setOption(option)
        switch (option) {
          case 'move-container':
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scanDestinationNavigator.go(origin)
            break
          case 'move-products':
          case 'move-loose-products':
            productListNavigator.go(origin)
            break
        }
      }
    }
  }
}
