import { useScanDestination } from './ScanDestination.hooks'
import {
  AtlasBodySubtitle,
  AtlasBodyTitle,
  AtlasColor,
  AtlasH1,
  CardComponent,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  StackAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import LocationAndContainerMoleculeWrapper from 'components/molecules/LocationAndContainerWrapper.molecule'
import LoaderAtom from '../../../components/atoms/Loader.atom'

export const ScanDestinationPage = () => {
  const {
    isLoading,
    actions,
    origin
  } = useScanDestination()
  const { t } = useTranslation('global')

  if (isLoading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <ToolbarMolecule title="Traslado de productos"
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.back} />
    <PageContentAtom $centered>
      <Filler />
      <Icon src={Icons.ScanIcon} />
      <AtlasBodyTitle>{t('scan-destination.scan')}</AtlasBodyTitle>
      <AtlasH1>{t('scan-destination.title')}</AtlasH1>
      <AtlasBodyTitle>{t('scan-destination.use-your-scanner')}</AtlasBodyTitle>
      <Filler />
      <CardComponent>
        <AtlasBodySubtitle color={AtlasColor.Neutral40}>Origen: </AtlasBodySubtitle>
        <StackAtom>
          <LocationAndContainerMoleculeWrapper origin={origin} />
        </StackAtom>
      </CardComponent>
    </PageContentAtom>
  </>
}
