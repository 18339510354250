import { useNavigate } from 'react-router-dom'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'

export const useGoToMoveOptionsNavigator = () => {
  const navigate = useNavigate()

  return {
    go: (origin: ScannedLocation | ScannedContainer) => {
      const text = origin.type === ScanType.Location ? origin.location.name : origin.container.name
      navigate(TRANSFER_MOVE_OPTIONS_URL.replace(':origin', text))
    }
  }
}

export const TRANSFER_MOVE_OPTIONS_URL = '/transfer/:origin/choose'
