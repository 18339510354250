import {
  Icons,
  NavigationMenuOrganism,
  ToolbarMolecule
} from '@justomx/atlas-components'
import { useSessionData } from 'hooks/session.hook'
import { Global } from 'utils/global'

interface MenuWrapperProps {
  title: string
  progress?: number
  secondaryAction?: string
  thirdAction?: string
  fourthAction?: string
  onSecondaryAction?: () => void
  onThirdAction?: () => void
  onFourthAction?: () => void
}

export const MenuWrapperOrganism = (props: MenuWrapperProps) => {
  const {
    title,
    progress,
    secondaryAction,
    thirdAction,
    fourthAction,
    onSecondaryAction,
    onThirdAction,
    onFourthAction
  } = props
  const { user, warehouse, country, logout } = useSessionData()

  return (
    <>
      {!!user?.menuItems?.length && (
        <NavigationMenuOrganism
          title={title}
          country={country}
          userEmail={user.username}
          userName={user.firstName}
          version="13.0.1"
          warehouseName={warehouse?.coreDescription || 'Pruebas'}
          onLogout={logout}
          menuItems={user.menuItems || []}
          isMenuOpen={false}
          progress={progress}
          secondaryActionIcon={secondaryAction}
          thirdActionIcon={thirdAction}
          fourthActionIcon={fourthAction}
          onSecondaryAction={onSecondaryAction}
          onThirdAction={onThirdAction}
          onFourthAction={onFourthAction}
        />
      )}

      {!user?.menuItems?.length && (
        <ToolbarMolecule
          title={title}
          actionIcon={Icons.HamburgerIcon}
          onAction={Global.WV.openMenu}
          progress={progress}
          secondaryActionIcon={secondaryAction}
          thirdActionIcon={thirdAction}
          onSecondaryAction={onSecondaryAction}
          onThirdAction={onThirdAction}
        />
      )}
    </>
  )
}
