import {AtlasBodySubtitle, Icons, PageContentAtom, ToolbarMolecule} from "@justomx/atlas-components";
import {useUniversalSearchContainer} from "./universal-search-container.hook";
import LoaderAtom from "../../../components/atoms/Loader.atom";
import {useTranslation} from "react-i18next";
import { ProductWithStock } from "../components/product-with-stock.component";
import ScannedLocationOrContainer from "../components/scanned-location-or-container.component";

export const UniversalSearchContainerPage = () => {
  const { container, products, loading, goBack} = useUniversalSearchContainer()
  const {t} = useTranslation('global')

  return <>
    {loading && <LoaderAtom>{t('loader.message')}</LoaderAtom>}
    <ToolbarMolecule 
      title={t('universal-search.container.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={goBack}
    />
    <PageContentAtom>
      <AtlasBodySubtitle>{t('universal-search.container.scanned-container')}</AtlasBodySubtitle>
      <ScannedLocationOrContainer item={container} type="container"/>
      <AtlasBodySubtitle>{t('universal-search.container.products-in-container')}</AtlasBodySubtitle>
      {products.map(e => <ProductWithStock key={e.productEan+'-'+e.container} product={e}/>)}
    </PageContentAtom>
  </>
}
