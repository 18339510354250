import { InventoryProductCardMolecule } from 'components/molecules/InventoryProductCard.molecule'
import { InventoryProductFixQuantityComponent } from 'components/molecules/InventoryProductFixQuantity'
import { useMoveOneConfiguration } from './MoveOneConfiguration.hooks'
import { Filler, Icons, PageContentAtom, PrimaryButtonAtom, ToolbarMolecule } from '@justomx/atlas-components'

function formatStock (stock: number, isWeighable: boolean): string {
  if (isWeighable) {
    return `${stock} g`
  } else {
    return `${stock} ${stock > 1 ? 'piezas' : 'pieza'}`
  }
}

export const MoveOneConfigurationPage = () => {
  const {
    inventoryProduct,
    canConfirm,
    origin,
    actions
  } = useMoveOneConfiguration()

  const onChangeQuantity = (value: string) => {
    actions.setQuantity(value)
  }

  return <>
    <ToolbarMolecule title="Productos"
                     actionIcon={Icons.ArrowBack}
                     onAction={actions.goBack} />
    <PageContentAtom>
      <InventoryProductCardMolecule inventoryProduct={inventoryProduct}
                                    subtitle={`Cantidad total: ${formatStock(inventoryProduct.stock, inventoryProduct?.byWeight)}`} />
      <InventoryProductFixQuantityComponent
        inventoryProduct={inventoryProduct}
        onChange={onChangeQuantity}
        location={origin.container?.name ?? origin.location.name}
        label="Cantidad a mover" />
      <Filler />
      <PrimaryButtonAtom disabled={!canConfirm()}
                         onClick={actions.confirm}>Confirmar</PrimaryButtonAtom>
    </PageContentAtom>
  </>
}
