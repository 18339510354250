import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTransferScanOriginNavigator } from '../scan-origin/ScanOrigin.navigator'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  transferDestinationInfoReader,
  transferOptionSelectedReader,
  transferOriginInfoReader,
  transferSelectedItemsReader
} from '../transfer.state'
import { useAtom } from 'jotai'
import { moveContainer, moveProducts } from '../../../client/move.client'
import { manageError } from '../../../utils/errors/error.handler'
import { Analytics } from '../../../analytics/analytics.events'
import { ScanType } from '../../../client/scanner/scanner.types'

export const useMoveAll = () => {
  const navigate = useNavigate()
  const transferScanOrigin = useTransferScanOriginNavigator()

  const [origin] = useAtom(transferOriginInfoReader)
  const [destination] = useAtom(transferDestinationInfoReader)
  const [option] = useAtom(transferOptionSelectedReader)
  const [products] = useAtom(transferSelectedItemsReader)

  const [searchParams] = useSearchParams()
  const hasLooseProducts = searchParams.has('hasLooseProducts')

  const [loading, setLoading] = useState<boolean>(false)

  return {
    origin,
    destination,
    inventoryProducts: products,
    actions: {
      move: () => {
        const tmpOrigin = {
          locationId: origin.location.legacyId,
          locationName: origin.location.name,
          locationType: origin.location.type,
          containerId: origin.container?.legacyId,
          containerName: origin.container?.name,
          containersInLocation: origin.type === ScanType.Location ? origin.totalContainers : 0
        }
        const tmpDestination = {
          locationId: destination.location.legacyId,
          locationName: destination.location.name,
          locationType: destination.location.type,
          containerId: destination.container?.legacyId,
          containerName: destination.container?.name,
          containersInLocation: destination.type === ScanType.Location ? destination.totalContainers : 0
        }
        setLoading(true)
        if (option === 'move-container') {
          moveContainer({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            source: origin.container!.name,
            target: destination.location.name
          })
            .then(() => {
              Analytics.traslation.moveContainer({
                origin: tmpOrigin,
                destination: tmpDestination
              })
              toast.success('Movido el contenedor ' + origin.container?.name + ' con éxito')
              transferScanOrigin.go()
            })
            .catch(manageError('Error al mover todos los productos'))
            .finally(() => {
              setLoading(false)
            })
        } else {
          const source = option === 'move-loose-products' ? origin.location.name : origin.container?.name ?? origin.location.name
          const target = destination.container?.name ?? destination.location.name
          moveProducts({
            products,
            source,
            target
          })
            .then(() => {
              const eventData = {
                inventoryProducts: products,
                origin: tmpOrigin,
                destination: tmpDestination
              }
              if (hasLooseProducts) {
                Analytics.traslation.moveLooseProducts(eventData)
              } else {
                Analytics.traslation.moveProducts(eventData)
              }
              if (products.length === 1) {
                toast.success(`Movido 1 SKU. Origen: ${origin.location.name}.Destino: ${destination.location.name}`)
              } else {
                toast.success(`Movidos ${products.length} productos. Origen: ${origin.location.name}. Destino: ${destination.location.name}`)
              }
              transferScanOrigin.go()
            })
            .catch(manageError('Error al mover productos'))
            .finally(() => {
              setLoading(false)
            })
        }
      },
      cancel: () => {
        toast.success('Movimiento cancelado con éxito')
        setLoading(false)
        transferScanOrigin.go()
      },
      back: () => {
        navigate(-1)
      }
    },
    loading
  }
}
