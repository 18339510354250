import { type RouteObject } from 'react-router-dom'
import { STORAGE_URL } from './Storage.navigator'
import { StoragePage } from './Storage.page'
import { STORAGE_CONTAINER_DETAIL_URL } from './container-detail/ContainerDetail.navigator'
import { StorageContainerDetailPage } from './container-detail/ContainerDetail.page'
import { STORAGE_SCAN_DESTINATION_URL } from './scan-destination/StorageScanDestination.navigator'
import { StorageScanDestinationPage } from './scan-destination/StorageScanDestination.page'

export const storageRoutes: RouteObject[] = [
  {
    id: '(Storage) Storage list',
    path: STORAGE_URL,
    element: <StoragePage />
  },
  {
    id: '(Storage) Container detail',
    path: STORAGE_CONTAINER_DETAIL_URL,
    element: <StorageContainerDetailPage />
  },
  {
    id: '(Storage) Scan destination',
    path: STORAGE_SCAN_DESTINATION_URL,
    element: <StorageScanDestinationPage />
  }
]
