import { useTranslation } from 'react-i18next'
import { useReverseLogistics } from './ReverseLogistics.hook'
import {
  AtlasBodySubtitle,
  PageContentAtom
} from '@justomx/atlas-components'
import { ProductCard } from '../components/product-card.component'
import TareInfo from '../components/tare-info.component'
import { OptionsDialogOrganism } from 'components/organisms/OptionsDialog.organism'
import { GenericOption } from 'types/model'
import EmptyListComponent from '../components/empty-list.component'
import LoaderAtom from 'components/atoms/Loader.atom'
import { InfoMessage } from '../components/info-message'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'

export const wasteOrMissingOptions: GenericOption[] = [
  { id: 'waste', label: 'Merma' },
  { id: 'missing', label: 'Faltante' }
]

export const defaultWasteOrMissingOption = 'waste'

export const ReverseLogisticsPage = () => {
  const { data, loading, actions, selectedProduct, dialogRef } = useReverseLogistics()
  const { t } = useTranslation('global')

  if(loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <>
    <MenuWrapperOrganism title={t('reverse-logistics.toolbar-title')} />
    <PageContentAtom>
      <AtlasBodySubtitle>{t('reverse-logistics.scan-tare-or-product')}</AtlasBodySubtitle>
      <InfoMessage message={t('reverse-logistics.info-message')}/>
      {data.length !== 0 ? data.map((item) => {
        return item.type === 'Tara' ?
          <TareInfo 
            id={item.tara?.barcode as string}
            orderId={item.tara?.orderNumber as string}
            priority={item.priority}
            key={item.id}
          />
          :
          <ProductCard
            key={item.id}
            product={item.products[0]}
            showPriority={true}
            showStock={true}
            showWasteIcon={true}
            openDialog={()=>actions.openWasteOrMissingDialog(item.products[0])}
          />
      })
      :
      <EmptyListComponent/>
      }
      <OptionsDialogOrganism
          dialogRef={dialogRef}
          title={t("reverse-logistics.select-report-type", {product: selectedProduct.name})}
          options={wasteOrMissingOptions}
          defaultOption={defaultWasteOrMissingOption}
          confirmAction={actions.goToWasteOrMissingPage}
        />
    </PageContentAtom>
  </>
}
