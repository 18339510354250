import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'
import { useNavigate } from 'react-router-dom'

export const TRANSFER_PRODUCT_LIST = '/transfer/:origin/chosen-products'

export const useMoveProductListNavigator = () => {
  const navigate = useNavigate()
  return {
    go: (origin: ScannedLocation | ScannedContainer) => {
      const text = origin.type === ScanType.Location ? origin.location.name : origin.container.name
      navigate(TRANSFER_PRODUCT_LIST.replace(':origin', text))
    }
  }
}
