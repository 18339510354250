import { useTranslation } from 'react-i18next'
import { useScanContainerLocation } from './ScanContainerLocation.hooks'
import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom
} from '@justomx/atlas-components'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'

export const ScanContainerLocationPage = () => {
  useScanContainerLocation()
  const { t } = useTranslation('global')

  return (
    <>
      <MenuWrapperOrganism title="Escanea" />
      <PageContentAtom $centered>
        <Filler flexGrow={1} />
        <Icon src={Icons.ScanIcon} />
        <AtlasBodyTitle>Escanea</AtlasBodyTitle>
        <AtlasH1 style={{ textAlign: 'center' }}>Ubicación<br /> ó<br /> Contenedor</AtlasH1>
        <AtlasBodyTitle>Usa tu escáner</AtlasBodyTitle>
        <Filler />
      </PageContentAtom>
    </>
  )
}
