import { getWarehouseByWmsName } from '@justomx/warehouse-commons'
import { type LoggedUser, UserManagment } from '@justomx/webview-commons'
import {
  getCurrentCountry,
  getCurrentWarehouse
} from 'client/utilities.client-helper'
import { getLoginUrl } from 'utils/environment'

export const useSessionData = () => {
  const country = getCurrentCountry()
  const warehouseCode = getCurrentWarehouse()
  const warehouse = getWarehouseByWmsName(warehouseCode)
  let user: LoggedUser | undefined

  try {
    user = UserManagment.getCurrentUser()
    console.info('user', user)
  } catch {
    user = undefined
  }

  const logout = () => {
    UserManagment.logout()
    window.location.href = getLoginUrl()
  }

  return { user, warehouse, country, logout }
}
