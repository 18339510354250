import {AtlasBodySubtitle, AtlasColor, CardComponent, CardRow, Icon, Icons, StackAtom, Divider, AtlasBodyMain} from "@justomx/atlas-components";
import { useTranslation } from "react-i18next";
import { ProductWithLocationInfo } from "../universal-search.types";

type LocationAndContainerWithStockProps = {
  item: ProductWithLocationInfo
  showContainer?: boolean
  showStock?: boolean
}

export function LocationAndContainer({item, showContainer = true, showStock}: LocationAndContainerWithStockProps) {
  const {t} = useTranslation('global')
  return <CardComponent key={item.location + "-" + item.container}>
    <StackAtom style={{flexDirection: 'column', alignItems: 'baseline'}} gap={12}>
      <StackAtom>
        {showContainer && <CardRow>
          <Icon src={Icons.IcPallet} size={18}/>
          <AtlasBodySubtitle>{item.container || '[SIN CONTENEDOR]'}</AtlasBodySubtitle>
        </CardRow>}
        <CardRow>
          <Icon src={Icons.IcUbicacionIcon} size={18}/>
          <AtlasBodySubtitle>{item.location}</AtlasBodySubtitle>
        </CardRow>
      </StackAtom>
      {showStock &&
        <> 
        <Divider/>
        <CardRow style={{ width: '100%', justifyContent: 'space-between'}}>
          <AtlasBodyMain color={AtlasColor.Neutral40}>{t('universal-search.quantity')}</AtlasBodyMain>
          <AtlasBodyMain>{item.stock} {item.isWeighable ? t('uom.grams') : t('uom.units')}</AtlasBodyMain>
        </CardRow>
        </>
      }
    </StackAtom>
  </CardComponent>;
}

