import type { LocationType, LocationZone, UomKey } from 'client/types'
import { isObject } from 'lodash'

export enum ScanType {
  Location = 'Location',
  Container = 'Container',
  Product = 'Product',
  Suppply = 'Supply'
}

export interface ScanResponse {
  type: ScanType
  product?: ProductResponse
  location?: LocationResponse
  totalContainers?: number
  container?: ContainerResponse
}

export interface ProductResponse {
  legacyId: string
  ean: string
  sku: string
  name: string
  image?: string
  isWeighable: boolean
  packagingLevels: PackagingLevel[]
}

export interface LocationResponse {
  id: string
  name: string
  type: LocationType
  zone?: LocationZone
  classification: 'Rack' | 'Unit'
  country: string
  warehouse: string
  legacyId: string
}

export interface ContainerResponse {
  id: string
  name: string
  country: string
  warehouse: string
  legacyId: string
}

interface SupplyItem {
  ean: string
  quantity: number
  sku: string
  zone: string
}

export interface SupplyResponse {
  id: string
  barcode: string
  consolidated: boolean
  country: string
  items: SupplyItem[]
  order: string
  status: string
  warehouse: string
}

export interface PackagingLevel {
  name: string
  quantity: number
  uom?: UomKey
}

export interface ScannedProduct {
  type: ScanType.Product
  product: ProductResponse
}

export interface ScannedContainer {
  type: ScanType.Container
  container: ContainerResponse
  location: LocationResponse
}

export interface ScannedLocation {
  type: ScanType.Location
  location: LocationResponse
  totalContainers: number
  container?: ContainerResponse
}

export interface ScannedSupply {
  type: ScanType.Suppply
  supply: LocationResponse
}

export type ScannedResponse =
  | ScannedProduct
  | ScannedContainer
  | ScannedLocation

export const isScannedProduct = (o: unknown): o is ScannedProduct =>
  isObject(o) && 'type' in o && o.type === ScanType.Product

export const isScannedContainer = (o: unknown): o is ScannedContainer =>
  isObject(o) && 'type' in o && o.type === ScanType.Container

export const isScannedLocation = (o: unknown): o is ScannedLocation =>
  isObject(o) && 'type' in o && o.type === ScanType.Location

export const isScannedResponse = (o: unknown): o is ScannedResponse =>
  isScannedProduct(o) || isScannedContainer(o) || isScannedLocation(o)
