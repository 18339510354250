import { type LocationAndContainer } from 'types/model'
import { MoveOrganism } from './Move.organism'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../client/scanner/scanner.types'

interface LocationAndContainerMoleculeProps {
  origin: ScannedLocation | ScannedContainer
  destination: ScannedLocation | ScannedContainer
}

const MoveOrganismWrapper = ({ origin, destination }: LocationAndContainerMoleculeProps) => {
  const transformedOrigin: LocationAndContainer = {
    locationId: origin.location.id,
    locationName: origin.location.name,
    locationType: origin.location.type,
    containerId: origin.container?.id,
    containerName: origin.container?.name,
    containersInLocation: origin.type === ScanType.Location ? origin.totalContainers : 0
  }
  const transformedDestination: LocationAndContainer = {
    locationId: destination.location.id,
    locationName: destination.location.name,
    locationType: destination.location.type,
    containerId: destination.container?.id,
    containerName: destination.container?.name,
    containersInLocation: destination.type === ScanType.Location ? destination.totalContainers : 0
  }
  return <MoveOrganism origin={transformedOrigin} destination={transformedDestination} />
}

export default MoveOrganismWrapper
