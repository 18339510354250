import {
  AtlasBodyTitle,
  AtlasH1,
  Filler,
  Icon,
  Icons,
  PageContentAtom, TextBoxMolecule
} from '@justomx/atlas-components'
import { useSearchProduct } from './SearchProduct.hook'
import { onErrorToast } from '../../../onErrorToast.util'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'

export const SearchProductPage = () => {
  const { loading, actions } = useSearchProduct(onErrorToast)
  const { t } = useTranslation('global')

  if (loading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return <>
    <MenuWrapperOrganism title={t('search-product.toolbar-title')} />
    <PageContentAtom $centered>
      <Filler/>
      <Icon src={Icons.ScanIcon}/>
      <AtlasBodyTitle>{t('search-product.scan')}</AtlasBodyTitle>
      <AtlasH1 style={{ textAlign: 'center' }}>{t('search-product.product')}</AtlasH1>
      <AtlasBodyTitle>{t('search-product.use-your-scanner')}</AtlasBodyTitle>
      <Filler/>
      <TextBoxMolecule label={t('search-product.enter-ean')} onNewValue={actions.goEAN}/>
    </PageContentAtom>
  </>
}
