import { FlexRowAtom } from 'components/atoms/FlexRow.atom'
import { type Location} from '../../types/model'
import { LocationAndContainerMolecule } from '../molecules/v2/LocationAndContainer.molecule'
import { AtlasBodyMain, AtlasCaption, AtlasColor, CardComponent, Icon, Icons } from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import { type ScannedContainer } from 'client/scanner/scanner.types'
import { SuggestedLocation } from 'pages/storage/Storage.types'

interface MoveSuggestionsOrganismProps {
  origin: ScannedContainer
  originText?: string
  destinations: SuggestedLocation[]
  destinationText?: string
}

export const MoveSuggestionsOrganism = ({ origin, destinations, originText, destinationText }: MoveSuggestionsOrganismProps) => {
  const { t } = useTranslation('global')

  const formattedOrigin = {
    location: origin.location.name,
    locationType: origin.location.type,
    container: origin.container.name
  }

  const formattedDestinations = destinations.map(destination => {
    return {
      location: destination.location,
      locationType: destination.type
    }
  })

  return (
    <CardComponent>
      <CardComponent style={{ gap: 8 }}>
        <AtlasCaption color={AtlasColor.Neutral40}>{originText ?? t('storage-scan-destination.origin')}</AtlasCaption>
        <LocationAndContainerMolecule data={formattedOrigin} />
      </CardComponent>
      <Icon src={Icons.ArrowForward} size={18} />
      <CardComponent style={{ gap: 8 }} $variant='highlight'>
        <AtlasCaption color={AtlasColor.Neutral40}>{destinationText ?? t('to-storage-destination-location.suggested')}</AtlasCaption>
        {formattedDestinations.length > 0 ? formattedDestinations.map(
          destination=> <LocationAndContainerMolecule data={destination} key={destination.location} />
        )
        :
        <AtlasCaption color={AtlasColor.Neutral40}>
          <FlexRowAtom style={{ gap: 8 }}>
            <Icon src={Icons.IcInformationIcon}/>
            <AtlasBodyMain>{t('storage-scan-destination.no-suggested-locations')}</AtlasBodyMain>
          </FlexRowAtom>
        </AtlasCaption>
        }
      </CardComponent>
    </CardComponent>
  )
}
