import {useScanner} from '../../../hooks/scanner.hook'
import {useNavigate} from 'react-router-dom'
import {useGoToInventoryProductFix} from '../product-fix/InventoryProductFix.navigator'
import {useGoToCreateProduct} from '../create-product/CreateProduct.navigator'
import {useEffect} from 'react'
import {manageError} from '../../../utils/errors/error.handler'
import {InventoryProduct, isSameEan} from "../../../types/model";
import { getProductFromScan } from 'client/scanner/scanner.client'
import { useAtom } from 'jotai'
import { inventoryFixOriginInfoReader, inventoryFixSelectedProductAtom, inventoryProductsReader } from '../InventoryFix.state'
import { findProductInfo } from 'client/products/products.client'

export const useScanProductInContainer = () => {

  const inventoryProductFix = useGoToInventoryProductFix()
  const createProduct = useGoToCreateProduct()

  const [origin] = useAtom(inventoryFixOriginInfoReader)
  const [products] = useAtom(inventoryProductsReader)
  const [, setSelectedProduct] = useAtom(inventoryFixSelectedProductAtom)

  const { keys, getScan } = useScanner()
  const navigate = useNavigate()

  useEffect(() => {
    const scanned = getScan()
    if (!scanned) return

    const scannedInventoryProduct = products.find(e => isSameEan(scanned, e.productEan))
    if (scannedInventoryProduct) {
      setSelectedProduct(scannedInventoryProduct)
      inventoryProductFix.go({
        origin,
        ean: scannedInventoryProduct.productEan
      })
      return
    }

    getProductFromScan(scanned)
      .then(async ({product}) => {
        const productInfo = await findProductInfo(product.ean)
        const formattedProduct: InventoryProduct = {
          productId: product.legacyId,
          productName: product.name,
          productSku: product.sku,
          productEan: product.ean,
          productImage: product.image as string,
          productZone: "",
          stock: 0
        }
        setSelectedProduct(formattedProduct)
        createProduct.go({
          origin,
          ean: scanned
        })
      })
      .catch(manageError('Producto no encontrado'))
  }, [keys])

  return {
    actions: {
      back: () => { navigate(-1) }
    }
  }
}
