import { storageAxiosInstance } from './Storage.axios.instance'
import type {
  ContainerWithAlerts,
  StorePartialProps,
  StoreResponse,
  StoreTotalProps,
  SuggestedLocation
} from './Storage.types'

export const getSuggestedLocationsInStorage = async (
  container: string
): Promise<SuggestedLocation[]> => {
  return await storageAxiosInstance()
    .get<SuggestedLocation[]>(`/v2/containers/${container}/suggestions`)
    .then((e) => e.data)
}

export const getContainersInPreStorage = async (): Promise<ContainerWithAlerts[]> => {
  return await storageAxiosInstance()
    .get<ContainerWithAlerts[]>('/v2/containers')
    .then((e) => e.data)
}

export const storeProducts = async ({
  source,
  target,
  products
}: StorePartialProps): Promise<StoreResponse> => {
  const url = `/v2/containers/${source}/store-partial`
  return await storageAxiosInstance().post(url, {
    target,
    products
  })
}

export const storeContainer = async ({
  source,
  target
}: StoreTotalProps): Promise<StoreResponse> => {
  const url = `/v2/containers/${source}/store`
  return await storageAxiosInstance().post(url, {
    target
  })
}
