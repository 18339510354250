import { type ScannedContainer, type ScannedLocation, ScanType } from '../../../client/scanner/scanner.types'
import { type TransferOptionSelected } from '../../../components/molecules/MoveProductsSelector.molecule'
import { LocationType, LocationZone } from '../../../client/types'

interface ValidationProps {
  origin: ScannedLocation | ScannedContainer
  destination: ScannedLocation | ScannedContainer
  flow: TransferOptionSelected
}

export function validationDestination (prop: ValidationProps): void {
  const {
    flow,
    destination,
    origin
  } = prop

  if (destination.location.type === LocationType.Receipt) {
    throw Error('O-1: No se puede escanear una ubicación o contenedor en ubicación de recibo')
  }

  if (destination.location.type === LocationType.Picking) {
    if (origin.location.name === destination.location.name) {
      throw Error('O-48: No se puede escanear la misma ubicación de picking de origen')
    }
  } else if (origin.container?.name === destination.container?.name && origin.location.name === destination.location.name) {
    throw Error('O-48: No se puede escanear en destino el mismo contenedor de origen o ubicación de origen')
  }

  if (flow === 'move-products' || flow === 'move-loose-products') {
    if (destination.container == null) {
      if (destination.location.type === LocationType.Storage && destination.location.zone === LocationZone.Groceries) {
        throw Error('O-31: No se puede mover productos a una ubicación de almacenamiento sin contenedor.')
      }

      if (destination.location.type === LocationType.PreStorage) {
        throw Error('O-31: No se puede mover productos a una ubicación de pre-almacenamiento sin contenedor.')
      }
    }

    /* FIXME: no se que tan necesario sea este condicional */
    if (destination.location.type === LocationType.Picking && destination.container) {
      destination.container = undefined
    }
  }

  if (flow === 'move-container') {
    if (destination.type === ScanType.Container) {
      throw new Error('O-42: No se puede trasladar un contenedor adentro de otro contenedor')
    }

    if (destination.location.type === LocationType.Storage) {
      if (destination.location.zone !== LocationZone.Groceries) {
        throw Error('O-43: No se puede trasladar un contenedor a una ubicación de almacenamiento que no lo permite')
      }
      if (destination.location.classification !== 'Unit' && destination.totalContainers && destination.totalContainers > 0) {
        throw Error('O-39: No se puede trasladar un contenedor a una ubicación de almacenamiento que ya tiene un contenedor.')
      }
    }

    if (destination.location.type === LocationType.Picking) {
      throw new Error('O-41: No se puede trasladar un contenedor a una ubicación de picking')
    }
  }
}
