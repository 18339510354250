import {
  AtlasBodyMain,
  AtlasBodyTitle,
  AtlasColor,
  BottomGroupMolecule,
  CardComponent,
  Icon,
  Icons,
  PageContentAtom,
  SnackbarInformationPanel,
  StackAtom
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import { LocationAndContainerMolecule } from '../../components/molecules/v2/LocationAndContainer.molecule'
import { type LocationInfo } from '../../types/model'
import { useStorageHomepage } from './Storage.hooks'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'
import { type ContainerWithAlerts } from './Storage.types'
import LoaderAtom from 'components/atoms/Loader.atom'

const ContainerCard = (params: {
  container: ContainerWithAlerts
  'data-test'?: string
}) => {
  const { container } = params

  const formattedContainer: LocationInfo = {
    location: container.location,
    locationType: 'PreStorage',
    container: container.container,
    alerts: container.alerts
  }

  return (
    <CardComponent data-test={params['data-test'] ?? 'container-card'}>
      <StackAtom gap={16}>
        <LocationAndContainerMolecule data={formattedContainer} />
      </StackAtom>
    </CardComponent>
  )
}

export const StoragePage = () => {
  const { t } = useTranslation('global')
  const { containers, isLoading } = useStorageHomepage()

  if (isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return (
    <>
      <MenuWrapperOrganism title={t('to-storage.title')} />
      <PageContentAtom>
        {containers.length > 0
          ? (
              containers.map((e) => {
                return (
                  <ContainerCard key={e.location + '-' + e.container} container={e} />
                )
              })
            )
          : (<AtlasBodyMain>{t('to-storage.no-containers')}</AtlasBodyMain>)}
      </PageContentAtom>
      <BottomGroupMolecule>
        <SnackbarInformationPanel
          style={{
            border: '1px solid',
            borderRadius: '12px',
            backgroundColor: AtlasColor.Primary10,
            borderColor: AtlasColor.Important
          }}
        >
          <Icon src={Icons.ScanIcon} size={40} />
          <AtlasBodyTitle>{t('to-storage.description')}</AtlasBodyTitle>
        </SnackbarInformationPanel>
      </BottomGroupMolecule>
    </>
  )
}
