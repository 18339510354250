import LocationAndContainerMoleculeWrapper from 'components/molecules/LocationAndContainerWrapper.molecule'
import { CardComponent } from '@justomx/atlas-components'
import { type ScannedContainer, type ScannedLocation } from '../../client/scanner/scanner.types'

interface SmallMoveOrganismProps {
  origin: ScannedLocation | ScannedContainer
}

export const SmallMoveOrganism = ({ origin }: SmallMoveOrganismProps) => {
  return <CardComponent $variant="highlight" style={{ gap: 0 }}>
    <LocationAndContainerMoleculeWrapper origin={origin} />
  </CardComponent>
}
