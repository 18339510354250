import { axiosInstance } from '../utils/axios.instance'
import { type InventoryProduct, type LocationAndContainer, normalizeEanForCapture, type Product } from '../types/model'
import { validateLocationAndContainer } from '../utils/locationAndContainer.validator'
import { bffRestockingAxiosInstance } from '../utils/bff-restocking.axios.instance'
import { type AlertsWrapper } from './alerts/alerts.types'
import { type ProductContainersWrapper } from './products/products.types'
import { getCurrentCountry, getCurrentWarehouse } from './utilities.client-helper'
import { RulesFromProductResponse } from './rules/rules.types'

interface HasData<T> {
  data: T
}

export async function getLocationOrContainerByLastNumbers (lastNumbers: string) {
  return await axiosInstance()
    .get(`/containers/lastNumbers/${lastNumbers}`)
    .then(e => e.data)
    .then(validateLocationAndContainer)
}

export async function getContainers (locationId: string) {
  return await axiosInstance()
    .get(`/containers/in-location/${locationId}`)
    .then(e => e.data)
}

export const getLocationOrContainerBasicInfo = async (lpnOrLocation: string, mustIgnoreContainer: boolean): Promise<LocationAndContainer> => {
  const skipContainerFlag = mustIgnoreContainer ? 'skipContainerValidation=true' : ''

  return await axiosInstance()
    .get<LocationAndContainer>(`/scan/${lpnOrLocation}?${skipContainerFlag}`)
    .then(e => e.data)
    .then(validateLocationAndContainer)
}

/** @deprecated since version 2.0 */
export const getInventoryProductsClient = async (locationId: string, containerId: string | null | undefined, looseProducts?): Promise<InventoryProduct[]> => {
  const params = new URLSearchParams()
  params.set('locationId', locationId)

  if (containerId) {
    params.set('containerId', containerId)
  }

  if (looseProducts) {
    params.set('hasContainers', 'false')
  }

  const url = '/products?' + params.toString()

  return await axiosInstance()
    .get<HasData<InventoryProduct[]>>(url)
    .then(e => e.data.data)
}

const getProductInfoRaw = async (url: string) => {
  return await axiosInstance().get<Product>(url).then(e => e.data)
}

export const getProductInfo = async (ean: string) => {
  const normalizedEan = normalizeEanForCapture(ean)
  const normalizedUrl = `/products/upc/${normalizedEan}`
  const rawUrl = `/products/upc/${ean}`

  return await getProductInfoRaw(normalizedUrl)
    .catch(async e => {
      if (e.response?.status === 404) {
        return await getProductInfoRaw(rawUrl)
      }
      throw e
    })
}

export const getProductsAlerts = async (productIds: string[]): Promise<AlertsWrapper> => {
  return await bffRestockingAxiosInstance()
    // need to use POST method to send multiple productIds in the body
    .post<AlertsWrapper>('/restocking?restocked=false', { productId: productIds })
    .then(e => e.data)
}

export const getProductContainers = async (ean: string): Promise<ProductContainersWrapper> => {
  return await axiosInstance()
    .get<ProductContainersWrapper>(`/products/bar-code/${ean}?warehouse=${getCurrentWarehouse()}&country=${getCurrentCountry()}`)
    .then(e => e.data)
}

export const getTotalStockInWarehouse = async (ean: string): Promise<{ stock: number }> => {
  return await axiosInstance()
    .get<{ stock: number }>(`/products/${ean}/total-stock`)
    .then(e => e.data)
}

export const getRulesFromProductClient = async (productId: string) => {
  return await bffRestockingAxiosInstance()
    .get<AlertsWrapper>(`/rules?productId=${productId}`)
    .then(e => e.data)
    .catch(err => { console.error(err) })
}
