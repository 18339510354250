import { inventoryWmsUtilsAxiosInstance } from 'utils/op-inventory-wms-utils.axios.instance'
import { axiosInstance } from '../../utils/axios.instance'

interface AdjustInventoryClientParams {
  ean: string
  source: string
  quantity: number
}

export const adjustInventoryClient = async ({
 ean,
 source,
 quantity
}: AdjustInventoryClientParams) => {
  const url = 'v2/adjustment'
  return await inventoryWmsUtilsAxiosInstance()
    .post(url, {
      ean,
      source,
      quantity
    })
}

export interface CreateInventoryProductClientProp {
  ean: string,
  source: string,
  lot?: string,
  expirationDate?: string,
  quantity: number
}

export const createInventoryProductClient = async ({ean, source, lot, expirationDate, quantity}: CreateInventoryProductClientProp) => {
  const url = 'v2/adjustment/create'
  const normalizedExpirationDate = expirationDate?.substring(0, 10)

  return await inventoryWmsUtilsAxiosInstance()
    .post(url, {
      ean,
      source,
      lot,
      expirationDate: normalizedExpirationDate,
      quantity
    })
}
