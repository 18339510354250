import { useRequiredParams } from '../../../hooks/params.hook'
import { useEffect, useState } from 'react'
import { useScanner } from '../../../hooks/scanner.hook'
import { useAtom } from 'jotai'
import { fastTraslationOrigin, fastTraslationProducts } from '../FastTraslation.state'
import { getProductContainers, getRulesFromProductClient } from '../../../client/utilities.client'
import { moveProducts, moveProductsMetrics } from '../../../client/move.client'
import { toast } from 'react-toastify'
import { useFastTraslation } from '../FastTraslation.navigator'
import { type InventoryProduct, type LocationAndContainer } from '../../../types/model'
import { useSearchParams } from 'react-router-dom'
import { useScanProductNavigator } from '../scan-product/ScanProduct.navigator'
import { type ProductLocation } from 'client/products/products.types'
import { Analytics } from '../../../analytics/analytics.events'
import { validateDestination } from './FastTraslationScanDestination.validator'
import { manageError } from '../../../utils/errors/error.handler'
import { getLocationOrContainerFromScan } from 'client/scanner/scanner.client'
import { toCommonLocationInfoFromScanResponse } from 'utils/commonLocationInfo'

export const useFastTraslationScanDestinationHook = (onError: (err: string) => void) => {
  const { ean, quantity } = useRequiredParams('ean', 'quantity')
  const { keys, getScan } = useScanner()
  const [inventoryProducts] = useAtom(fastTraslationProducts)
  const [origin] = useAtom(fastTraslationOrigin)
  const fastTraslationHome = useFastTraslation()
  const [searchParams] = useSearchParams()
  const scanProductNavigator = useScanProductNavigator(onError)
  const inventoryProduct = inventoryProducts.find(e => e.productEan === ean)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [suggestedLocations, setSuggestedLocations] = useState<ProductLocation[]>([])
  const [currentLocations, setCurrentLocations] = useState<ProductLocation[]>([])

  async function move (destination: LocationAndContainer) {
    if (!origin || !inventoryProduct) return

    const productToMove: InventoryProduct = {
      ...inventoryProduct,
      stock: Number(quantity)
    }

    await moveProducts({
      products: [productToMove],
      source: origin.containerName || origin.locationName,
      target: destination.containerName || destination.locationName
    })

    moveProductsMetrics({
      product: productToMove,
      origin,
      destination
    })

    Analytics.restock({
      count: productToMove.stock,
      ean: productToMove.productEan,
      origin,
      destination,
      alertLevel: inventoryProduct.alert
    })

    toast.success(`Movidos ${quantity} productos. Origen: ${origin.locationName}. Destino: ${destination.locationName}`)
  }

  useEffect(() => {
    const scanned = getScan()
    if (scanned && origin && inventoryProduct && !showLoader) {
      setShowLoader(true)
      getLocationOrContainerFromScan(scanned)
        .then(destination => {
          const commonLocationInfo = toCommonLocationInfoFromScanResponse(destination)
          return validateDestination(commonLocationInfo)
        })
        .then(destination => {
          move(destination)
            .then(() => {
              setTimeout(
                async () => {
                  if (searchParams.get('isFromAlert') === 'true') {
                    await scanProductNavigator.goFromPalletOrLPN(origin)
                  } else {
                    fastTraslationHome.go()
                  }
                  setShowLoader(false)
                }, 2000
              )
            })
            .catch(e => {
              console.error(e)
              if (e.response?.status === 404) {
                onError(scanned + ' não encontrado.')
              } else if (e.response?.status === 403 || e.response?.status === 401) {
                onError('Você não tem permissões para executar esta ação. Fale com seu administrador.')
              } else {
                onError('Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde')
              }
              setShowLoader(false)
            })
        })
        .catch(manageError(`FastTraslationScanDestination.hooks.ts#useEffect ${scanned}`))
        .finally(() => {
          setShowLoader(false)
        })
    }
  }, [keys, showLoader, origin])

  /** Get suggested and current locations **/
  useEffect(() => {
    if (inventoryProduct) {
      // get suggested
      getRulesFromProductClient(inventoryProduct.productId)
        .then(data => {
          if (data.data?.length > 0) {
            setSuggestedLocations(data.data[0].locations)
          }
        })
        .catch(e => {
          console.error(e)
          onError(e.message as string)
        })

      // get current
      getProductContainers(inventoryProduct.productEan)
        .then(data => { setCurrentLocations(data.locations) })
        .catch(e => {
          console.error("Silent error: couldn't get actual picking locations")
          onError(e.message as string)
        })
    }
  }, [inventoryProduct])

  return {
    showLoader,
    quantity: Number(quantity),
    suggestedLocations: suggestedLocations.map(l => l.locationName),
    currentLocations: currentLocations.map(l => l.locationName),
    ean
  }
}
