import { MoveProductsSelectorMolecule } from '../../../components/molecules/MoveProductsSelector.molecule'
import { useMoveOptions } from './MoveOptions.hook'
import { Icons, PageContentAtom, ToolbarMolecule } from '@justomx/atlas-components'
import LoaderAtom from '../../../components/atoms/Loader.atom'
import { useTranslation } from 'react-i18next'

export const MoveOptionsPage = () => {
  const { t } = useTranslation('global')
  const {
    isLoading,
    actions,
    options,
    origin
  } = useMoveOptions()

  if (isLoading) {
    return (
      <LoaderAtom>{t('loader.message')}</LoaderAtom>
    )
  }

  return (<>
    <ToolbarMolecule
      title={origin?.container?.name ?? origin?.location.name}
      actionIcon={Icons.ArrowBack}
      onAction={actions.back}
    />
    <PageContentAtom>
      {options.map((it) => {
        return (
          <MoveProductsSelectorMolecule key={it} type={it} onClick={actions.onClickGo(it)} />
        )
      })}
    </PageContentAtom>
  </>)
}
