import { type LocationAndContainer } from 'types/model'
import { LocationAndContainerMolecule } from './LocationAndContainer.molecule'
import { type ScannedContainer, type ScannedLocation, ScanType } from '../../client/scanner/scanner.types'

interface LocationAndContainerMoleculeProps {
  origin: ScannedLocation | ScannedContainer
}

const LocationAndContainerMoleculeWrapper = ({ origin }: LocationAndContainerMoleculeProps) => {
  const transformedOrigin: LocationAndContainer = {
    locationId: origin.location.id,
    locationName: origin.location.name,
    locationType: origin.location.type,
    containerId: origin.container?.id,
    containerName: origin.container?.name,
    containersInLocation: origin.type === ScanType.Location ? origin.totalContainers : 0
  }
  return <LocationAndContainerMolecule location={transformedOrigin} />
}

export default LocationAndContainerMoleculeWrapper
