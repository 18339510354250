import { useNavigate } from 'react-router-dom'
import { type ScannedContainer, type ScannedLocation } from '../../../client/scanner/scanner.types'

export const useGoToChooseContainerNavigator = () => {
  const navigate = useNavigate()

  return {
    go: (origin: ScannedLocation | ScannedContainer) => {
      navigate(TRANSFER_CHOOSE_CONTAINER_URL.replace(':origin', origin.location.name))
    }
  }
}

export const TRANSFER_CHOOSE_CONTAINER_URL = '/transfer/:origin/choose-container'
