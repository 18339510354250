import { useTranslation } from 'react-i18next'
import { useScanSupplies } from './ScanSupplies.hook'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasListAtom,
  BottomGroupMolecule,
  Filler,
  Icons,
  PageContentAtom,
  PrimaryButtonAtom,
  ToolbarMolecule
} from '@justomx/atlas-components'
import TareInfo from '../../components/tare-info.component'
import { GenericOption } from 'types/model'
import LoaderAtom from 'components/atoms/Loader.atom'
import { TextfieldDialogOrganism } from 'components/organisms/TextfieldDialog.organism'
import HistoryList from 'pages/reverse-logistics/components/history-list.component'
import EmptyListComponent from 'components/molecules/EmptyList.molecule'
import { MenuWrapperOrganism } from 'components/organisms/MenuWrapper.organism'

export const wasteOrMissingOptions: GenericOption[] = [
  { id: 'waste', label: 'Merma' },
  { id: 'missing', label: 'Faltante' }
]

export const defaultWasteOrMissingOption = 'waste'

export const ScanSuppliesPage = () => {
  const { scannedSupplies, selectedSupplies, loading, actions, dialogRef } = useScanSupplies()
  const { t } = useTranslation('global')

  if(loading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  return <>
    <MenuWrapperOrganism
      title={t('reverse-logistics.scan-supplies.toolbar-title')}
      secondaryAction={Icons.IcKeyboardIcon}
      onSecondaryAction={actions.openSearchDialog}
    />
    <PageContentAtom>
      <AtlasBodySubtitle>{t("reverse-logistics.scan-supplies.instructions")}</AtlasBodySubtitle>
        <AtlasListAtom>
          <li><AtlasBodyMain display='contents'>{t('reverse-logistics.scan-supplies.add')}</AtlasBodyMain></li>
          <li><AtlasBodyMain display='contents'>{t("reverse-logistics.scan-supplies.delete")}</AtlasBodyMain></li>
        </AtlasListAtom>
        {selectedSupplies.length > 0  && scannedSupplies.length === 0 && 
          <HistoryList onClick={actions.continueWithLastList} />
        }
      {scannedSupplies.length > 0 ?
        scannedSupplies.map((item) => 
          <TareInfo 
            id={item.tara?.barcode as string}
            orderId={item.tara?.orderNumber as string}
            priority={item.priority}
            key={item.id}
            onDelete={() => actions.delete(item.id)}
          />
        )
        :
        <EmptyListComponent text="Sin taras escaneadas"/>
      }
      <Filler />
      <Filler />
      <Filler />
      <Filler />
      <BottomGroupMolecule>
        <PrimaryButtonAtom onClick={actions.continue} disabled={scannedSupplies.length===0}>
            {t('reverse-logistics.scan-supplies.continue')}
        </PrimaryButtonAtom>
      </BottomGroupMolecule>
      <TextfieldDialogOrganism
        dialogRef={dialogRef}
        title="Escribe el código de la tara"
        label="Código"
        confirmText="Continuar"
        cancelText="Cancelar"
        confirmAction={actions.searchSupply}
      />
    </PageContentAtom>
  </>
}
