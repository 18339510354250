import { useNavigate } from 'react-router-dom'
import { type LocationAndContainer, type InventoryProduct } from '../../../types/model'
import { fastTraslationOrigin, fastTraslationProducts } from '../FastTraslation.state'
import { useAtom } from 'jotai'
import { getInventoryProductsClient, getProductsAlerts } from '../../../client/utilities.client'
import _ from 'lodash'
import { type Alerts, type PalletItem } from '../../../client/alerts/alerts.types'
import { alertComparator } from '../../../utils/alerts.utils'
import { findProductsInfo } from '../../../client/products/products.client'
import {  UomKey } from '../../../client/types'

export type InventoryProductWithAlertInfo =
  InventoryProduct
  & {
    alert: 'none' | 'urgent' | 'critical' | 'warning'
    locations: string[]
    expectedStock: number
    currentStock: number
  }

export const useScanProductNavigator = (onError: (str: string) => void) => {
  const navigate = useNavigate()
  const [, setOrigin] = useAtom(fastTraslationOrigin)
  const [, setProducts] = useAtom(fastTraslationProducts)

  async function go (locationAndContainer: PalletItem | LocationAndContainer, inventoryProducts: InventoryProduct[], isFromAlert) {
    const productsWithAlert = await getProductsAlerts(inventoryProducts.map(product => product.productId))

    const productsWithCriticalAlert = productsWithAlert.data.filter(product => product.state === 'critical')

    const productsWithWarningAlert = productsWithAlert.data.filter(product => product.state === 'warning')

    const inventoryProductsWithAlertInfo = inventoryProducts.map((product: InventoryProduct): InventoryProductWithAlertInfo => {
      const productAlertResult: InventoryProductWithAlertInfo = {
        ...product,
        alert: 'none',
        locations: [],
        expectedStock: 0,
        currentStock: 0
      }

      let alerts: Alerts[] = []

      const criticalAlerts = productsWithCriticalAlert.filter(e => e.productId === product.productId)

      if (criticalAlerts.length) {
        productAlertResult.alert = 'critical'
        alerts = criticalAlerts
      } else {
        const warningAlerts = productsWithWarningAlert.filter(e => e.productId === product.productId)
        if (warningAlerts.length) {
          productAlertResult.alert = 'warning'
          alerts = warningAlerts
        }
      }

      productAlertResult.locations = _.flatMap(
        alerts,
        alert => alert.locations.map(e => e.name)
      )

      productAlertResult.expectedStock = alerts.length ? Math.max(...alerts.map(e => e.expectedStock)) : 0

      // Preciso stock
      productAlertResult.currentStock = alerts.length ? Math.max(...alerts.map(e => e.stock)) : 0

      return productAlertResult
    })
    const sortedInventoryProductsWithAlertInfo = inventoryProductsWithAlertInfo
      .sort(alertComparator)

    setOrigin(locationAndContainer)
    setProducts(sortedInventoryProductsWithAlertInfo)
    if (isFromAlert) {
      navigate(SCAN_PRODUCT_NAVIGATOR_ROUTE + '?isFromAlert=true')
    } else {
      navigate(SCAN_PRODUCT_NAVIGATOR_ROUTE)
    }
  }

  async function goFromPalletOrLPN (origin: PalletItem | LocationAndContainer) {
    const inventoryProducts = await getInventoryProductsClient(origin.locationId, origin.containerId)
      .then(async (products) => {
        const tmpProducts = await findProductsInfo(products.map((it) => it.productEan))
        return products.map((p) => {
          const tmp = tmpProducts.find((it) => it.ean === p.productEan)
          if (tmp != null) {
            p.byWeight = tmp.packagingLevels.some((pl) => pl.uom === UomKey.Weight)
          }
          return p
        })
      })

    if (inventoryProducts.length === 0) {
      onError('El LPN ' + origin.containerName + ' no tiene productos')
      return
    }
    await go(origin, inventoryProducts, true)
  }

  return {
    go,
    goFromPalletOrLPN
  }
}

export const SCAN_PRODUCT_NAVIGATOR_ROUTE = '/fast-traslation/scan-product'
