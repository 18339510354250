const prodUrls = ['https://op-utilities-front.pages.dev/', 'https://op-utilities-front.justo.cloud/']

export const isProduction = () => {
  return prodUrls.some(e => location.href.startsWith(e))
}

export const getLoginUrl = () => {
  if (isProduction()) {
    return 'https://operations-login.pages.dev/'
  }

  return 'https://staging.operations-login.pages.dev/'
}
