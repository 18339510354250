import { type ProductWithAlertInfo, type InventoryProduct, Product } from '../../../types/model'
import { AtlasBodySubtitle, AtlasColor, CardComponent, CardDivider, CardRow } from '@justomx/atlas-components'
import { ProductInfoAtom } from 'components/atoms/ProductInfo.atom'
import { useTranslation } from 'react-i18next'

interface ProductCardComponentProps {
  onClick?: (p: InventoryProduct) => void
  product: ProductWithAlertInfo
  selected?: boolean
  selectable?: boolean
  subtitle?: string
  'data-test'?: string
  variant?: 'urgent' | 'critical' | 'warning'
}

export const InventoryProductCardMolecule = (props: ProductCardComponentProps) => {
  const {
    onClick,
    product,
    selected = false,
    selectable = false,
    subtitle,
    variant
  } = props

  const { t } = useTranslation('global')

  const variantTheme = {
    critical: '2px solid #FF6771',
    warning: '2px solid #FF9E67'
  }

  const formattedProduct: Product = {
    productId: product.id,
    productName: product.name,
    productSku: product.sku,
    productEan: product.ean,
    productImage:  product.image as string,
    productZone: product.zone,
    stock: product.stock,
    isWeighable: product.isWeighable
  }

  return (
    <CardComponent
      color={AtlasColor.Neutral40}
      onClick={onClick}
      data-test={props['data-test']}
      style={{
        backgroundColor: '#171717',
        border: variant != null ? variantTheme[variant] : '1px solid #404040'
      }}
    >
      <CardRow $gap={16}>
        <ProductInfoAtom
          product={formattedProduct}
          selectable={selectable}
          selected={selected}
          data-test={props['data-test'] + '-info'}
        />
      </CardRow>
      <CardDivider />
      <CardRow>
        <AtlasBodySubtitle
          data-stock={product.stock}>{subtitle ?? t('inventory-product-card.count', { value: product.stock })}</AtlasBodySubtitle>
      </CardRow>
    </CardComponent>
  )
}
