import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {useProduct} from '../../../hooks/product.hook'
import {useGoToInventoryProductList} from '../product-list/InventoryProductList.navigator'
import {toast} from 'react-toastify'
import {createInventoryProductClient, type CreateInventoryProductClientProp} from '../InventoryFix.client'
import {Analytics} from "../../../analytics/analytics.events";
import {useCurrentLocationAndContainer} from "../../../hooks/currentLocation.hook";
import { useAtom } from 'jotai'
import { inventoryFixOriginInfoReader, inventoryFixSelectedProductReader } from '../InventoryFix.state'

interface NewInventory {
  quantity: number
  lot: string
  expiration: string
}

function validateNewInventory ({ lot, quantity, expiration }: NewInventory) {
  if (quantity && !lot?.trim() && !expiration?.trim()) {
    return {}
  }

  return lot?.trim() &&
  quantity &&
  expiration?.trim()
    ? {}
    : { disabled: true } // TODO: Move this to a better place
}

export const useCreateProduct = () => {
  const navigate = useNavigate()
  const inventoryList = useGoToInventoryProductList()

  const [origin] = useAtom(inventoryFixOriginInfoReader)
  const [product] = useAtom(inventoryFixSelectedProductReader)
 
  const [newInventory, setNewInventory] = useState<NewInventory>({
    quantity: 0,
    lot: '',
    expiration: ''
  })
  const [loading, setLoading] = useState<boolean>(false)
  
  const canConfirm = validateNewInventory(newInventory)

  return {
    product,
    origin,
    newInventory,
    canConfirm,
    actions: {
      confirm: async () => {
        setLoading(true)
        try {
          const source = origin.container?.name || origin.location?.name!
          const newProductInventory: CreateInventoryProductClientProp = {
            ean: product.productEan,
            source,
            quantity: newInventory.quantity
          }
  
          if (newInventory.expiration) {
            newProductInventory.expirationDate = newInventory.expiration
          }
  
          if (newInventory.lot) {
            newProductInventory.lot = newInventory.lot
          }

          await createInventoryProductClient(newProductInventory)

          Analytics.inventoryFix.createProduct({
            location: {
              locationName: origin.location?.name as string,
              locationType: origin.location?.type as string,
              containerId: origin.container?.legacyId as string,
              containerName: origin.container?.name as string
            },
            count: newInventory.quantity,
            ean: product.productEan
          })

          toast.success('Se ha creado el SKU: ' + product.productSku)
          setTimeout(() => {
            setLoading(false)
            inventoryList.go(origin)
          }, 2000)
        } catch (e) {
          console.error('Error on create inventory', e)
          setLoading(false)
          toast.error('Error al crear nuevo inventario. ' + e?.message)
        }
      },
      goBack: () => {
        navigate(-1)
      },
      setQuantity: (quantity: number) => {
        setNewInventory(state => ({ ...state, quantity }))
      },
      setLot: (lot: string) => {
        setNewInventory(state => ({ ...state, lot }))
      },
      setExpiration: (expiration: string) => {
        setNewInventory(state => ({ ...state, expiration }))
      }
    },
    loading
  }
}
